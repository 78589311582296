import { CircularProgress } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { LinearCellPercentageGraph, TableCell, TableRow } from '../../../Components';
import { ContentIcon } from '../../../Sections/Broadcasts/Common/ContentIcon';
import { ContentReader } from '../../../Readers';
import {
  StyledBroadcastTitle,
  StyledRecurringLabel,
  StyledSendingProgress
} from '../../../Sections/Broadcasts/List/styles';
import { DateTime, Flex } from '../../../Common/Component';
import { StyledFailIcon } from '../RecentTable/style';
import { EventStatus } from '../../../Events/Component';
import { ListedDelivery } from '../../../Models';
import { BroadcastsPermission, EventAndMetadata, MinimalBroadcastCategory } from '../../Model';
import { usePermissions } from '../../../Security/Hook';
import { NonWrappingCell } from '../../../Forms/Component';
import { CategoryInput } from '../Categories';
import { StyledCategoryInputContainer } from './style';
import {useEnvironmentSettings, useMounted} from '../../../Common/Hook';
import SendIcon from '../../../Assets/img/icons/streamline/send-email-1-emails.svg';
import RecallIcon from '../../../Assets/img/icons/streamline/road-sign-banned.svg';
import { useApi, useContextOrThrow, useLoggedInAuthDescription } from '../../../Core/Hook';
import { TableRowContextMenu } from '../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import { PopOverState } from '../../../Common/Model';
import { RecallDialog } from '../RecallDialog/RecallDialog';
import { RecalledLabelWithTooltip } from '../RecalledLabelWithTooltip/RecalledLabelWithTooltip';
import { ToastContext } from '../../../Core/Context';
import { useArchiveBroadcastAction, useDuplicateBroadcastAction, useViewReportBroadcastAction } from '../../Hook';
import {
  InlinePersonAvatarAndName
} from '../../../Common/Component/InlinePersonAvatarAndName/InlinePersonAvatarAndName';

type Props = {
  delivery: ListedDelivery;
  eventAndMetadata?: EventAndMetadata;
  onChange: (delivery: ListedDelivery) => void;
  onReloadRequired: () => void;
};

export const RecentDeliveryRow: FunctionComponent<Props> = ({
  delivery,
  eventAndMetadata,
  onChange,
  onReloadRequired,
}) => {
  const { user: { id: authedUserId } } = useLoggedInAuthDescription();
  const intl = useIntl();
  const api = useApi();
  const mounted = useMounted();
  const { permissionAvailable, guardedCallback } = usePermissions();
  const { broadcastDeliverySuccessStatsEnabled } = useEnvironmentSettings();
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [rowActive, setRowActive] = useState<boolean>(false);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [recallDialogState, setRecallDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const [recalling, setRecalling] = useState<boolean>(false);
  const [sendingToNew, setSendingToNew] = useState<boolean>(false);
  const [performingAction, setPerformingAction] = useState<boolean>(false);
  const userCanAssignCategory = useMemo(() => (
    permissionAvailable(BroadcastsPermission.CATEGORIES_ASSIGN_ALL)
    || (
      permissionAvailable(BroadcastsPermission.CATEGORIES_ASSIGN_OWN)
      && `${ delivery.createdBy.id }` === `${ authedUserId }`
    )
  ), [authedUserId, delivery.createdBy.id, permissionAvailable]);
  const {
    archiveConfirmationDialog,
    archiveAction,
    archiving,
  } = useArchiveBroadcastAction(delivery.broadcastId, null, performingAction);
  const {
    duplicateAction,
    duplicating,
  } = useDuplicateBroadcastAction(
    delivery.broadcastId,
    performingAction,
  );
  const viewReportAction = useViewReportBroadcastAction(
    delivery.broadcastId,
    delivery.status === 'delivered',
    delivery.id,
  );

  useEffect(() => {
    setPerformingAction(duplicating || archiving || recalling || sendingToNew)
  }, [archiving, duplicating, recalling, sendingToNew]);

  useEffect(() => {
    if (!rowActive) {
      return;
    }

    const onTouchOutside: EventListener = event => {
      if (event.target === rowRef.current || (rowRef.current && event.target && rowRef.current.contains(event.target as Node))) {
        return;
      }

      setRowActive(false);
      document.removeEventListener('touchstart', onTouchOutside);
    };

    document.addEventListener('touchstart', onTouchOutside);
    return () => document.removeEventListener('touchstart', onTouchOutside);
  });

  const whenMouseEnter = useCallback(() => {
    if (!userCanAssignCategory) {
      return;
    }

    setRowActive(true);
  }, [userCanAssignCategory]);

  const whenCategoryChanged = useCallback((category: MinimalBroadcastCategory) => (
    onChange({
      ...delivery,
      broadcast: {
        ...delivery.broadcast,
      },
      category,
    })
  ), [delivery, onChange]);

  const whenSendToNewClicked = useCallback(() => {
    setSendingToNew(true);
    api.post(`/broadcasts/${ delivery.broadcastId }/send-to-new`)
      .then(() => (
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              description: 'Toast message when broadcast is sent to new users successfully.',
              defaultMessage: 'Broadcast sent successfully.',
            })
          );
          setSendingToNew(false);
          onReloadRequired();
        }, 50)
      ))
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            description: 'Toast message when broadcast cannot be sent to new users.',
            defaultMessage: 'Broadcast could not be sent.',
          })
        );
        setSendingToNew(false);
      });
  }, [addErrorToast, addSuccessToast, api, delivery.broadcastId, intl, mounted, onReloadRequired]);

  const whenRecallConfirmed = useCallback((message: string) => {
    setRecallDialogState(PopOverState.WILL_CLOSE);
    setRecalling(true);
    api.post(`/broadcasts/deliveries/${ delivery.id }/recall`, { reason: message || null })
      .then(() => (
        setTimeout(() => {
          if (!mounted) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              description: 'Toast message when broadcast is recalled successfully.',
              defaultMessage: 'Broadcast recalled successfully.',
            })
          );
          setRecalling(false);
          onReloadRequired();
        }, 50)
      ))
      .catch(() => {
        if (!mounted) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            description: 'Toast message when broadcast cannot be recalled.',
            defaultMessage: 'Broadcast could not be recalled.',
          })
        );
        setRecalling(false);
      })
  }, [api, delivery.id, mounted, addSuccessToast, intl, onReloadRequired, addErrorToast]);

  const actions = useMemo(() => [
    ...(permissionAvailable(BroadcastsPermission.CREATE) ? [duplicateAction] : []),
    ...(permissionAvailable(BroadcastsPermission.REPORT) ? [viewReportAction] : []),
    ...(delivery.status !== 'recalled' && (
      permissionAvailable(BroadcastsPermission.RECALL_ALL)
      || (delivery.createdBy.id === authedUserId && permissionAvailable(BroadcastsPermission.RECALL_OWN))
    ) ? [
        {
          id: 'recall',
          IconComponent: RecallIcon,
          label: intl.formatMessage({
            description: 'Label for recall action in recent deliveries action menu.',
            defaultMessage: 'Recall broadcast',
          }),
          busy: recalling,
          disabled: performingAction,
          onClick: delivery.createdBy.id === authedUserId
            ? guardedCallback(() => setRecallDialogState(PopOverState.OPEN), [BroadcastsPermission.RECALL_OWN])
            : guardedCallback(() => setRecallDialogState(PopOverState.OPEN), [BroadcastsPermission.RECALL_ALL]),
        }
      ] : []),
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [archiveAction] : []),
    ...(delivery.status !== 'recalled' && permissionAvailable(BroadcastsPermission.CREATE) ? [
        {
          id: 'sendToNew',
          IconComponent: SendIcon,
          label: intl.formatMessage({
            description: 'Label for send to new action in recent deliveries action menu.',
            defaultMessage: 'Send to new',
          }),
          busy: sendingToNew,
          disabled: performingAction,
          onClick: guardedCallback(whenSendToNewClicked, [BroadcastsPermission.CREATE]),
        }
      ] : []),
  ], [
    archiveAction,
    authedUserId,
    delivery.createdBy.id,
    delivery.status,
    duplicateAction,
    guardedCallback,
    intl,
    performingAction,
    permissionAvailable,
    recalling,
    sendingToNew,
    viewReportAction,
    whenSendToNewClicked
  ]);

  return (
    <>
      <TableRow
        ref={ rowRef }
        onTouchStart={ whenMouseEnter }
        onMouseEnter={ whenMouseEnter }
        onMouseLeave={ () => setRowActive(false) }
      >
        <TableCell>
          { delivery.broadcast.contents.length && (
            <ContentIcon
              contentType={ ContentReader.contentTypeFromString(delivery.broadcast.contents[0].type) }
            />
          ) }
        </TableCell>
        <TableCell>
          <Flex gap={ 1 }>
            <LineClamp minWidth={ 200 }>
              {
                ['delivered', 'recalled'].includes(delivery.status)
                  ? (
                    <StyledBroadcastTitle
                      to={ `/broadcasts/${ delivery.broadcastId }/report?delivery=${ delivery.id }` }
                    >
                      <LineClamp lines={ 2 }>
                        { delivery.broadcast.name }
                      </LineClamp>
                    </StyledBroadcastTitle>
                  )
                  : (
                    <LineClamp lines={ 2 }>
                      { delivery.broadcast.name }
                    </LineClamp>
                  )
              }
            </LineClamp>
            { delivery.source === 'recurrence' && (
              <StyledRecurringLabel>
                <FormattedMessage
                  defaultMessage="Recurring"
                  description="Label displayed next to broadcast title to indicate recurring schedule."
                />
              </StyledRecurringLabel>
            ) }
            { delivery.status === 'recalled' && <RecalledLabelWithTooltip recall={ delivery.recall }/> }
          </Flex>
        </TableCell>
        <TableCell>
          <StyledCategoryInputContainer>
            <CategoryInput
              broadcastId={ delivery.broadcastId }
              selectedCategory={ delivery.category }
              onSelectedCategoryChange={ whenCategoryChanged }
              containerActive={ rowActive }
              onClose={ () => setRowActive(false) }
              userCreatedBroadcast={ `${ delivery.createdBy.id }` === `${ authedUserId }` }
            />
          </StyledCategoryInputContainer>
        </TableCell>
        <TableCell>
          <LineClamp minWidth={ 200 }>
            <InlinePersonAvatarAndName person={ delivery.createdBy }/>
          </LineClamp>
        </TableCell>
        <NonWrappingCell>
          {
            delivery.status === 'queued'
              ? (
                <StyledSendingProgress>
                  <CircularProgress size="1.5rem" color="secondary"/>
                  <FormattedMessage
                    id="broadcast.list.sending"
                    defaultMessage="Sending&hellip;"
                    description="Sent status for queued deliveries"
                  />
                </StyledSendingProgress>
              )
              : delivery.status === 'failed'
                ? (
                  <Flex gap={ 2 }>
                    <StyledFailIcon/>
                    <span>
                      <FormattedMessage
                        id="broadcast.list.sending"
                        description="Sent status for failed deliveries."
                        defaultMessage="Failed"
                      />
                    </span>
                  </Flex>
                )
                : <DateTime dateTime={ delivery.deliveredAt }/>
          }
        </NonWrappingCell>
        <TableCell>
          <FormattedNumber
            value={ delivery.report.counts.recipients.active + delivery.report.counts.recipients.inactive }
          />
        </TableCell>
        <TableCell>
          {
            eventAndMetadata
              ? (
                <EventStatus
                  contentType={ ContentReader.contentTypeFromString(eventAndMetadata.event.type) }
                  status={ eventAndMetadata }
                  withName={ true }
                />
              )
              : eventAndMetadata === null
                ? (
                  <CircularProgress size="1.5rem" color="secondary"/>
                )
                : (
                    broadcastDeliverySuccessStatsEnabled
                      ? (
                        delivery.report.counts.recipients.notificationDelivered > 0
                          ? <LinearCellPercentageGraph
                              percentage={
                                delivery.report.counts.recipients.notificationDelivered
                                / (delivery.report.counts.recipients.notificationAttempted)
                              }
                          />
                          : <>-</>
                      )
                      : (
                        <LinearCellPercentageGraph
                          percentage={
                            delivery.report.counts.responded.total > 0
                              ? (
                                (delivery.report.counts.responded.total || 0)
                                / (delivery.report.counts.recipients.active + delivery.report.counts.recipients.inactive)
                              )
                              : 0
                          }
                        />
                      )
                )
          }
        </TableCell>
        <NonWrappingCell>
          <TableRowContextMenu
            id={ `${ delivery.id }_menu` }
            actions={ actions }
          />
        </NonWrappingCell>
      </TableRow>
      { archiveConfirmationDialog }
      { recallDialogState !== PopOverState.CLOSED && (
        <RecallDialog
          state={ recallDialogState }
          onStateChange={ setRecallDialogState }
          onSubmit={ whenRecallConfirmed }
        />
      ) }
    </>
  );
};
