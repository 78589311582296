import { ComponentProps, forwardRef, JSX, ReactNode } from 'react';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { Popover } from './Popover';

type Props<T> = {
  buttonLabel: string;
  buttonVariant?: ComponentProps<typeof Button>['variant'];
  items: T[];
  renderItem: (item: T) => ReactNode;
  getItemKey?: (item: T) => string | number;
};

export const PopoverMenu = <T, >({
  buttonLabel,
  buttonVariant = 'primary',
  items,
  renderItem,
  getItemKey,
}: Props<T>): JSX.Element => (
  <Popover
    ToggleComponent={ forwardRef((props, ref) => (
      <Button
        ref={ ref }
        variant={ buttonVariant }
        { ...props }
      >
        { buttonLabel }
      </Button>
    )) }
    ContentComponent={ forwardRef((props, ref) => (
      <menu
        ref={ ref }
        { ...props }
        css={ theme => ({
          boxShadow: theme.new.shadow.md,
          border: theme.new.border.standard,
          borderRadius: theme.new.borderRadius.standard,
          listStyle: 'none',
        }) }
      >
        { items.map((item, itemIndex) => (
          <li
            key={ getItemKey ? getItemKey(item) : itemIndex }
            css={ {
              position: 'relative',
              margin: 0,
              padding: 0,
          } }
          >
            { renderItem(item) }
          </li>
        )) }
      </menu>
    )) }
  />
);
