import { ComponentProps, FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import HomeIcon from '../../../lib/assets/icon/figma/home-line.svg?react';
import ChevronIcon from '../../../lib/assets/icon/figma/chevron-right.svg?react';
import MenuIcon from '../../../lib/assets/icon/figma/dots-horizontal.svg?react';
import { BreadcrumbButton } from '../../../lib/components/BreadcrumbButton/BreadcrumbButton';
import { Select } from '../Select/Select';
import { DropdownItem } from '../DropdownItem/DropdownItem';
import { StringTruncator } from '../../../lib/utility/StringTruncator/StringTruncator';
import { Tooltip } from '../Tooltip/Tooltip';

type BreadcrumbButtonProps = Partial<ComponentProps<typeof BreadcrumbButton>>;

type Props<T> = {
  items: T[];
  homeButtonProps: BreadcrumbButtonProps;
  getItemKey: (item: T, index: number) => string;
  getButtonProps: (item: T, final: boolean) => BreadcrumbButtonProps;
  getMenuItemProps: (option: T) => ComponentProps<typeof DropdownItem>;
  includeBackground?: boolean;
};

export const Breadcrumb = <T, >({
  items,
  homeButtonProps,
  getItemKey,
  getMenuItemProps,
  getButtonProps,
  includeBackground = false,
}: Props<T>) => {
  const [calculateOnRerender, setCalculateOnRerender] = useState(false);
  const [truncatedItemValue, setTruncatedItemValue] = useState<undefined | string>(undefined);
  const [inlineItemCount, setInlineItemCount] = useState(items.length);
  const firstInlineItemIndex = items.length - inlineItemCount;
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [container, setContainer] = useState<HTMLDivElement>(null);
  const [ignoreFirstMutation, setIgnoreFirstMutation] = useState<boolean>(false);
  const inlineItems = useMemo(() => (
    items.slice(firstInlineItemIndex)
  ), [firstInlineItemIndex, items]);
  const menuItems = useMemo(() => (
    items.slice(0, firstInlineItemIndex)
  ), [firstInlineItemIndex, items]);
  const reconnectObservers = useRef<() => void>();
  const renderButton = useCallback((item: T, final: boolean, index: number) => {
    const key = getItemKey(item, index);
    const additionalProps = getButtonProps(item, final);

    return (
      <Fragment key={ key }>
        {
          final && truncatedItemValue !== undefined
            ? (
              <Tooltip contents={ additionalProps.children }>
                <span>
                  <BreadcrumbButton
                    { ...additionalProps }
                  >
                    { truncatedItemValue }
                  </BreadcrumbButton>
                </span>
              </Tooltip>
            )
            : (
              <>
                <BreadcrumbButton
                  { ...additionalProps }
                />
                { !final && <Chevron/> }
              </>
            )
        }
      </Fragment>
    );
  }, [getButtonProps, getItemKey, truncatedItemValue]);

  const menuButton = useMemo(() => (
    <BreadcrumbButton
      padding="uniform"
    >
      <PresentationIcon
        IconComponent={ MenuIcon }
        size={ 5 }
      />
    </BreadcrumbButton>
  ), []);

  const inlineBreadcrumbItems = useMemo(() => (
    inlineItems.map((item, itemIndex) => (
      renderButton(
        item,
        itemIndex >= inlineItems.length - 1,
        itemIndex,
      )
    ))
  ), [inlineItems, renderButton]);

  const ref = useCallback((node: HTMLDivElement) => {
    if (!node) {
      return;
    }

    setContainer(node);
  }, []);

  const reset = useCallback(() => {
    setInlineItemCount(Infinity);
    setCalculateOnRerender(true);
  }, []);

  useEffect(() => {
    if (!calculateOnRerender) {
      return;
    }

    setIgnoreFirstMutation(true);
    setCalculateOnRerender(false);
    reconnectObservers.current && reconnectObservers.current();
  }, [calculateOnRerender, inlineItemCount]);

  useEffect(() => {
    if (!calculateOnRerender || !container) {
      return;
    }

    let removedItemCount = 0;
    let truncatedItemValue: string | undefined = undefined;

    // First evaluate which items to relegate to menu, then evaluate which characters to remove from the last item
    // in order to fit within available space.
    const adjustBreadcrumbItemsToFit = () => {
      if (container.scrollWidth <= container.clientWidth) {
        return;
      }

      // Duplicate breadcrumb with fixed width and make invisible. We perform the calculation on an invisible clone
      // to avoid flickering.
      const containerStyle = getComputedStyle(container);
      const fixedContainerWidth = parseFloat(containerStyle.width);
      const clone = container.cloneNode(true) as HTMLDivElement;
      // clone.style.opacity = '0';
      clone.style.pointerEvents = 'none';
      clone.style.width = `${ fixedContainerWidth }px`;
      clone.style.position = 'absolute';
      clone.style.top = '0';
      clone.style.left = '0';
      document.body.appendChild(clone);

      // We need to add placeholders for the menu button and dividing chevron so that they are factored into the
      // calculation.
      const menuButtonPlaceholder = document.createElement('div');
      const chevronPlaceholder = document.createElement('div');
      menuButtonPlaceholder.style.width = `${ MENU_BUTTON_PX_WIDTH }px`;
      menuButtonPlaceholder.style.flexShrink = '0';
      chevronPlaceholder.style.width = `${ CHEVRON_PX_WIDTH }px`;
      chevronPlaceholder.style.flexShrink = '0';
      clone.insertBefore(chevronPlaceholder, clone.children[2]);
      clone.insertBefore(menuButtonPlaceholder, clone.children[2]);

      // Determine which items to remove
      determineItemsToRemove(clone);

      if (items.length - removedItemCount > 1 || clone.scrollWidth <= clone.clientWidth) {
        // If there is room for multiple inline items after the previous step, there's no need to
        // truncate the final item and we can return after saving determined inline item count.
        setInlineItemCount(items.length - removedItemCount);
        document.body.removeChild(clone);
        return;
      }

      // Determine truncation on last item
      determineTruncation(clone);

      // Remove duplicate
      document.body.removeChild(clone);

      // Save determined values
      setInlineItemCount(items.length - removedItemCount);
      setTruncatedItemValue(truncatedItemValue);
    }

    // Evaluate which items to relegate to menu
    const determineItemsToRemove = (cloneContainer: HTMLDivElement) => {
      const reduceBreadcrumbItems = () => {
        const childArray = Array.from(cloneContainer.children);

        if (childArray.length === 5) {
          // Final item besides Home, menu and 2 separator chevrons. We don't want to remove this regardless of
          // if it fits, so we can exit early.
          return;
        }

        // Remove items after menu item, we remove 2 to also remove separator chevron.
        const childrenToRemove = childArray.slice(4, 6);
        removedItemCount += 1;
        for (const childToRemove of childrenToRemove) {
          cloneContainer.removeChild(childToRemove);
        }

        if (cloneContainer.scrollWidth <= cloneContainer.clientWidth) {
          // No more items need to be relegated to menu and we can finish this step.
          return;
        }

        reduceBreadcrumbItems();
      };

      reduceBreadcrumbItems();
    };

    // Evaluate which characters to remove from last visible item to fit
    const determineTruncation = (containerClone: HTMLDivElement) => {
      const lastButton = containerClone.lastChild as HTMLButtonElement;
      const initialValue = lastButton.textContent;

      // Set value to the smallest meaningful value
      lastButton.innerText = StringTruncator.truncateCentrally(initialValue, MINIMUM_MEANINGFUL_STRING_LENGTH);

      if (containerClone.scrollWidth > containerClone.clientWidth) {
        // Smallest meaningful value doesn't fit, so there's no point in continuing.
        return;
      }

      let maxCharacters = initialValue.length - 1;

      const reduceLabelLength = () => {
        maxCharacters -= 1;
        lastButton.innerText = StringTruncator.truncateCentrally(initialValue, maxCharacters);

        if (containerClone.scrollWidth <= containerClone.clientWidth) {
          truncatedItemValue = lastButton.innerText;
          return;
        }

        reduceLabelLength();
      };

      reduceLabelLength();
    };

    adjustBreadcrumbItemsToFit();
  }, [calculateOnRerender, container, items.length])

  // Bind resize listener when breadcrumb container node changes
  useEffect(() => {
    if (!container) {
      return;
    }

    const callback = () => {
      reflowObserver.disconnect();
      reset();
    }

    // Watch for items added to or removed from breadcrumb
    const reflowObserver = new MutationObserver(() => {
      if (ignoreFirstMutation) {
        setIgnoreFirstMutation(false);
        return;
      }

      callback();
    });

    reconnectObservers.current = () => {
      reflowObserver.observe(container, { childList: true });
    }

    reconnectObservers.current();

    return () => {
      reflowObserver.disconnect();
      reconnectObservers.current = null;
    };
  }, [container, ignoreFirstMutation, reset]);

  const renderOption = useCallback((item: T) => (
    <DropdownItem
      { ...getMenuItemProps(item) }
    />
  ), [getMenuItemProps]);

  return (
    <div
      ref={ ref }
      css={ theme => ({
        ...includeBackground ? { backgroundColor: theme.new.palette.grey[25].main } : {},
        borderRadius: theme.new.borderRadius.standard,
        padding: theme.new.spacing[1],
        display: 'inline-flex',
        maxWidth: '100%',
        gap: 4,
        overflow: 'hidden',
      }) }
    >
      <BreadcrumbButton
        padding="uniform"
        { ...homeButtonProps }
      >
        <PresentationIcon
          IconComponent={ HomeIcon }
          size={ 5 }
        />
      </BreadcrumbButton>
      <Chevron/>
      { !!menuItems.length && (
        <>
          <Select<T>
            open={ dropdownOpen }
            onOpenChange={ setDropdownOpen }
            onChange={ () => { /* No-op */
            } }
            options={ menuItems }
            renderOption={ renderOption }
            selectedItems={ [] }
          >
            { menuButton }
          </Select>
          <Chevron/>
        </>
      ) }
      { inlineBreadcrumbItems }
    </div>
  );
};

const Chevron: FC = () => (
  <PresentationIcon
    IconComponent={ ChevronIcon }
    size={ 4 }
    palette={ {
      colour: 'grey',
      intensity: 300,
    } }
  />
);

// These values are necessary for the calculation, but they're a pain to obtain dynamically
// as they're not rendered initially or while fitting the breadcrumb children.
const MENU_BUTTON_PX_WIDTH = 28;
const CHEVRON_PX_WIDTH = 16;
// Arbitrary minimum length of final inline menu item considered meaningful when truncated
const MINIMUM_MEANINGFUL_STRING_LENGTH = 15;
