import React, { FC } from 'react';
import { SpeedDialProps } from '@mui/lab';
import { Backdrop } from '@mui/material';

import { StyledFabWithBackdrop, StyledSpeedDial } from './style';
import AddIcon from '../../../../Asset/Icon/Streamline/add.svg';
import { PresentationIcon } from '../../Content/PresentationIcon/PresentationIcon';

type Props = SpeedDialProps & {
  position?: 'fixed' | 'absolute';
};

export const Fab: FC<Props> = ({
  icon,
  FabProps,
  position = 'fixed',
  open,
  onClose,
  ...props
}) => (
  <StyledFabWithBackdrop position={ position }>
    <Backdrop
      style={ { zIndex: 1, pointerEvents: 'all' } }
      open={ open }
      onClick={ event => onClose && onClose(event, 'toggle') }
    />
    <StyledSpeedDial
      { ...props }
      icon={ icon || <PresentationIcon IconComponent={ AddIcon }/> }
      FabProps={ FabProps || { size: 'large' } }
      open={ open }
    />
  </StyledFabWithBackdrop>
);
