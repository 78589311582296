import { ComponentProps, JSX, JSXElementConstructor, ReactNode, RefObject, forwardRef, useEffect, useState } from 'react';
import { keyframes } from '@emotion/react';

import { ButtonBase, ButtonBaseProps } from '../ButtonBase/ButtonBase';
import { ProgressCircle } from '../ProgressCircle/ProgressCircle';

type BaseComponent = (keyof JSX.IntrinsicElements) | JSXElementConstructor<never>;

type Props<C extends keyof JSX.IntrinsicElements | JSXElementConstructor<never> = 'button'> = {
  variant?: 'secondary' | 'primary' | 'secondary-destructive' | 'primary-destructive' | 'tertiary' | 'tertiary-grey';
  busy?: boolean;
  disabled?: boolean;
  attention?: boolean;
  children?: ReactNode;
} & Omit<ButtonBaseProps<C>, 'variant' | 'disabled'>;

export const Button = forwardRef(<C extends BaseComponent = 'button'>({
  variant,
  busy,
  disabled,
  children,
  attention,
  ...componentProps
}: Props<C>, ref): JSX.Element => {
  const [resettingAnimation, setResettingAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (!resettingAnimation) {
      return;
    }

    setResettingAnimation(false);
  }, [resettingAnimation]);

  return (
    <ButtonBase<C>
      {
        ...variant === 'secondary'
          ? {
            colour: 'grey',
            variant: 'secondary-grey',
          }
          : {}
      }
      {
        ...variant === 'secondary-destructive'
          ? {
            colour: 'error',
            variant: 'secondary-colour',
          }
          : {}
      }
      {
        ...variant === 'primary'
          ? {
            colour: 'primary',
            variant: 'filled-light',
          }
          : {}
      }
      {
        ...variant === 'primary-destructive'
          ? {
            colour: 'error',
            variant: 'filled-light',
          }
          : {}
      }
      {
        ...variant === 'tertiary'
          ? {
            colour: 'primary',
            variant: 'tertiary-dark',
          }
          : {}
      }
      {
        ...variant === 'tertiary-grey'
          ? {
            colour: 'grey',
            variant: 'tertiary-dark',
          }
          : {}
      }
      disabled={ disabled || busy }
      component={ componentProps.component as C }
      {
        ...attention && !resettingAnimation
          ? {
            css: {
              animation: `${ attentionShake } 100ms`,
              animationIterationCount: 4,
              animationFillMode: 'both',
            },
          }
          : {}
      }
      { ...componentProps as ComponentProps<typeof ButtonBase<C>> }
      {
        ...attention && componentProps.onClick
          ? {
            onClick: event => {
              setResettingAnimation(true);
              componentProps.onClick(event);
            }
          }
          : {}
      }
      ref={ ref }
    >
      { busy && (
        <div
          css={ {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          } }
        >
          <ProgressCircle
            size="xs"
            palette={ {
              colour: variant === 'secondary'
                ? 'grey'
                : ['secondary-destructive', 'primary-destructive'].includes(variant)
                  ? 'error'
                  : 'primary',
              intensity: 600,
              contrast: ['primary', 'primary-destructive'].includes(variant),
            } }
          />
        </div>
      ) }
      <div
        css={ {
          ...componentProps.fillParent ? { width: '100%' } : {},
          ...busy ? { opacity: 0 } : {},
        } }
      >
        { children }
      </div>
    </ButtonBase>
  );
});

const attentionShake = keyframes`
    25% {
        transform: translateX(0.1em);
    }

    75% {
        transform: translateX(-0.1em);
    }

    100% {
        transform: translateX(0);
    }
`;
