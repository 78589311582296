export class StringTruncator {
  // Truncates a string in the center. The center can be offset slightly to fall on a natural word break. Keeping the
  // end of the string intact is slightly prioritised over the start, my logic being it's probably harder to intuit.
  public static truncateCentrally = (str: string, maxLength: number, maxCenterOffset = 3): string => {
    const characterArray = Array.from(str);
    const charactersToRemove = str.length - maxLength;
    const spaceIndices = characterArray.reduce<number[]>(
      (spaceIndices, character, characterIndex) => (
        /\s/.exec(character)
          ? spaceIndices.concat(characterIndex)
          : spaceIndices
      ),
      [],
    );
    const actualCenterIndex = Math.ceil(str.length / 2);
    const spaceIndicesWithinAllowedOffset = spaceIndices.filter(spaceIndex => (
      Math.abs(actualCenterIndex - spaceIndex) <= maxCenterOffset
    ));
    const offsetCenterIndex = spaceIndicesWithinAllowedOffset.reduce<number | undefined>(
      (closestSpaceIndex, spaceIndex) => {
        if (closestSpaceIndex === undefined) {
          return spaceIndex;
        }

        const currentClosestOffset = Math.abs(actualCenterIndex - closestSpaceIndex);
        const newOffset = Math.abs(actualCenterIndex - spaceIndex);

        return currentClosestOffset < newOffset ? closestSpaceIndex : spaceIndex;
      },
      undefined,
    ) || actualCenterIndex;

    const firstHalf = str.slice(0, offsetCenterIndex - 1 - (maxLength % 2 === 0 ? 1 : 0) - Math.floor(charactersToRemove / 2)).trim();
    const secondHalf = str.slice(offsetCenterIndex + Math.floor(charactersToRemove / 2)).trim();
    return [firstHalf, secondHalf].join('…');
  };
}
