import { FC, ReactNode } from 'react';
import { DialogProps } from '@mui/material';

import { ButtonProps } from '../../Input/Button/Button';
import { SvgComponent } from '../../../../../src/react/Common/Model/SvgComponent';
import { PresentationIcon } from '../../../../Core/Component/Content/PresentationIcon/PresentationIcon';
import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogTitleRow
} from './style';

type Props = {
  open: boolean;
  IconComponent?: SvgComponent;
  destructive?: boolean;
  title: string;
  description: ReactNode;
  actions: ButtonProps[];
  onClose: () => void;
  TransitionProps?: DialogProps['TransitionProps'];
};

export const Dialog: FC<Props> = ({
  open,
  IconComponent,
  destructive,
  title,
  description,
  actions,
  onClose,
  TransitionProps,
}) => (
  <StyledDialog
    TransitionProps={ TransitionProps }
    fullWidth
    maxWidth="sm"
    open={ open }
    onClose={ onClose }
  >
    <StyledDialogTitleRow destructive={ destructive }>
      { IconComponent && <PresentationIcon IconComponent={ IconComponent } /> }
      <StyledDialogTitle>{ title }</StyledDialogTitle>
    </StyledDialogTitleRow>
    <StyledDialogContent>
      { description }
    </StyledDialogContent>
    <StyledDialogActions>
      { actions.map((buttonProps, buttonIndex) => (
        <StyledButton
          key={ buttonProps.key || buttonProps.id || buttonIndex }
          { ...buttonProps }
        />
      )) }
    </StyledDialogActions>
  </StyledDialog>
);
