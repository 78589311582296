import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';

import { DropdownItem } from '../../../stories/components/DropdownItem/DropdownItem';
import { TextFieldLeadingButton } from '../TextFieldLeadingButton/TextFieldLeadingButton';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { Select } from '../../../stories/components/Select/Select';
import { CountryInformation, Username } from '../../../stories/components/UsernameField/UsernameField';
import EmailIcon from '../../assets/icon/figma/mail-01.svg?react';
import { DropdownItemCheck } from '../../../stories/components/DropdownItemCheck/DropdownItemCheck';
import DownArrowIcon from '../../assets/icon/figma/chevron-down.svg?react';
import { Typography } from '../../../stories/components/Typography/Typography';

type UsernameOption = {
  type: 'email',
} | {
  type: 'countryInformation',
  value: CountryInformation,
};

type Props = {
  username: Username;
  onChange: (selectedType: Username['selectedType']) => void;
  defaultCountryCode: CountryCode;
  excludeEmail?: boolean;
};

export const UsernameSelect: FC<Props> = ({
  username,
  onChange,
  defaultCountryCode,
  excludeEmail = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const intl = useIntl();
  const countryInformation = useMemo<CountryInformation[]>(() => {
    const displayNames = new Intl.DisplayNames(intl.locale, { type: 'region' });
    const getCountryInformation = (countryCode: CountryCode): CountryInformation | undefined => {
      let callingCode: string;

      try {
        callingCode = getCountryCallingCode(countryCode)
      } catch {
        return;
      }

      return ({
        code: countryCode,
        callingCode,
        name: displayNames.of(countryCode) || countryCode,
      });
    };

    const countries = getCountries();
    const defaultCountryInformation = getCountryInformation(defaultCountryCode );
    return (defaultCountryInformation ? [defaultCountryInformation] : [])
      .concat(
        countries
          .map(getCountryInformation)
          .sort(sortCountriesByName)
          .filter(countryInformation => countryInformation.code !== defaultCountryCode)
      );
  }, [defaultCountryCode, intl.locale]);

  const options: UsernameOption[] = useMemo(() => [
    ...excludeEmail ? [] : [{ type: 'email' } as const],
    ...countryInformation.map(countryInformation => ({
      type: 'countryInformation',
      value: countryInformation
    } as UsernameOption)),
  ], [countryInformation]);

  const selectedOption: UsernameOption = useMemo(() => {
    if (username.selectedType !== undefined && username.selectedType !== 'email') {
      return options.find(
        option => option.type === 'countryInformation'
          && option.value.code === username.selectedType
      );
    }
    if (username.parsedPhone?.determinedCountryCode) {
      return options.find(
        option => option.type === 'countryInformation'
          && option.value.code === username.parsedPhone.determinedCountryCode
      );
    }
    return excludeEmail ? options[0] : options.find(option => option.type === 'email');
  }, [countryInformation, options, username, defaultCountryCode]);

  const whenSelectionChanged = useCallback((selectedItems: UsernameOption[]) => {
    if (selectedItems.length < 2) {
      return;
    }

    onChange(
      selectedItems[1].type === 'email'
        ? 'email'
        : selectedItems[1].value.code
    );
  }, [onChange, username, defaultCountryCode]);

  const buttonValue = useMemo(() => (
    selectedOption.type === 'email'
      ? (
        <PresentationIcon
          IconComponent={ EmailIcon }
          palette={ {
            colour: 'grey',
            intensity: 400,
          } }
        />
      )
      : `+${ selectedOption.value.callingCode }`
  ), [selectedOption]);

  return (
    <Select<UsernameOption>
      align="start"
      open={ open }
      onOpenChange={ setOpen }
      selectedItems={ [selectedOption] }
      options={ options }
      renderOption={ (option, Indicator) => (
        option.type === 'email'
          ? (
            <DropdownItem
              startAdornment={
                <PresentationIcon
                  IconComponent={ EmailIcon }
                  palette={ {
                    colour: 'grey',
                    intensity: 400,
                  } }
                />
              }
              text={ intl.formatMessage({
                description: 'Label for email address option in username field dropdown',
                defaultMessage: 'Email address',
              }) }
              endAdornment={
                <Indicator>
                  <DropdownItemCheck/>
                </Indicator>
              }
            />
          )
          : (
            <DropdownItem
              text={ option.value.name }
              supportingText={ `+${ option.value.callingCode }` }
              endAdornment={
                <Indicator>
                  <DropdownItemCheck/>
                </Indicator>
              }
            />
          )
      ) }
      onChange={ whenSelectionChanged }
    >
      <TextFieldLeadingButton
        onClick={ event => event.stopPropagation() }
        css={ { position: 'relative' } }
      >
        <Typography>
          { buttonValue }
        </Typography>
        <PresentationIcon
          IconComponent={ DownArrowIcon }
          size={ 3 }
          css={ {
            right: 0,
            transition: 'transform 0.1s',
            ...open ? { transform: 'rotate(180deg)' } : {},
          } }
        />
      </TextFieldLeadingButton>
    </Select>
  );
};

const sortCountriesByName = (
  countryA: CountryInformation,
  countryB: CountryInformation,
) => (
  countryA.name < countryB.name
    ? -1
    : countryA.name === countryB.name
      ? 0
      : 1
);
