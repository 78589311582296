import { FC, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { CountryCallingCode, CountryCode } from 'libphonenumber-js';

import { TextField } from '../TextField/TextField';
import { UsernameParser } from '../../../lib/utility/UsernameParser/UsernameParser';
import { UsernameSelect } from '../../../lib/components/UsernameSelect/UsernameSelect';

type Props = {
  username: Username;
  onChange: (username: Username) => void;
  defaultCountryCode: CountryCode;
  errorMessages?: string[];
  excludeEmail?: boolean;
  autoComplete?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
};

export interface CountryInformation {
  code: CountryCode;
  callingCode: CountryCallingCode;
  name: string;
}

export type Username = {
  value: string;
  selectedType?: 'email' | CountryCode;
  parsedPhone?: {
    determinedCountryCode: CountryCode;
    e164Value: string;
    nationalValue: string;
  },
};

export const UsernameField: FC<Props> = ({
  username,
  defaultCountryCode,
  onChange,
  errorMessages,
  excludeEmail = false,
  autoComplete = false,
  autoFocus = false,
  placeholder,
}) => {
  const intl = useIntl();
  const localUsername = useRef(username);

  const whenChanged = useCallback(
    (username: Username) => {
      localUsername.current = UsernameParser.parseUsername(
        username,
        defaultCountryCode,
      );
      onChange(localUsername.current);
    },
    [onChange],
  );

  const whenTypeChanged = useCallback((selectedType: Username['selectedType']) => {
    whenChanged({
      ...username,
      selectedType,
    });
  }, [whenChanged, username]);

  const whenValueChanged = useCallback((value: string) => {
    whenChanged({
      ...username,
      value,
    });
  }, [whenChanged, username]);

  /**
   * Ensure that any username changes are captured in the localUsername ref. Without this it is possible
   * for the username and localUsername to get out of sync.
   */
  useEffect(() => {
    localUsername.current = username;
  }, [localUsername, username]);

  /**
   * When the username input loses focus, make the input pretty.
   * We use localUsername to avoid race conditions where the selected type hasn't yet propagated to the parent
   * and back down to this component. Without this the blur callback can emit a stale username value. This was
   * most noticeable on Firefox.
   */
  const whenBlur = useCallback(() => {
    whenChanged({
      ...localUsername.current,
      value: localUsername.current.parsedPhone?.nationalValue ?? localUsername.current.value.trim(),
    });
  }, [whenChanged, localUsername]);

  return (
    <TextField
      startAdornment={
        <UsernameSelect
          defaultCountryCode={ defaultCountryCode }
          onChange={ whenTypeChanged }
          username={ username }
          excludeEmail={ excludeEmail }
        />
      }
      value={ username.value }
      onChange={ whenValueChanged }
      onBlur={ whenBlur }
      errorMessages={ errorMessages }
      inputProps={ {
        autoComplete: autoComplete ? excludeEmail ? 'tel' : 'username' : undefined,
        autoFocus: autoFocus,
      } }
      placeholder={ placeholder }
      label={
        excludeEmail
          ? intl.formatMessage({
            description: 'Label for phone number input.',
            defaultMessage: 'Phone number',
          })
          : intl.formatMessage({
            description: 'Label for username input on login page where user is unidentified.',
            defaultMessage: 'Email address or phone number',
          })
      }
    />
  );
};
