import { FC, ReactNode } from 'react';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { ConfiguredSidebar } from '../../../Common/Component/ConfiguredSidebar/ConfiguredSidebar';
import { useContextOrThrow } from '../../../Core/Hook';
import { useLocalStorageHookContext } from '../../../Common/Hook';

type Props = {
  children?: ReactNode
}

export const SidebarLayout: FC<Props> = ({
  children,
}) => {
  const screenWidth = useContextOrThrow(BreakpointContext);
  const getStorageHook = useLocalStorageHookContext();
  const useMenuExpandedPreference = getStorageHook<boolean>('mainMenu.expanded', true);
  const [expanded, setExpanded] = useMenuExpandedPreference();

  return (
    <div
      css={ theme => ({
        '--header-height': '0px',
        '--sidebar-width': expanded ? '310px' : '80px',
        display: 'flex',
        height: '100%',
        width: '100%',
        transition: '--sidebar-width 200ms',

        [theme.new.breakpoints.sm.down]: {
          '--header-height': '64px',
          '--sidebar-width': '0',
          transition: 'initial',
          gridTemplateColumns: 'auto',
        },
      }) }
    >
      { !screenWidth.lessThan.sm && (
        <ConfiguredSidebar
          expanded={ expanded }
          onExpandedChange={ setExpanded }
        />
      ) }
      { children }
    </div>
  )
}