import { FC, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import { PopoverMenu } from './PopoverMenu';

type Props = {
  title: string;
  startDate: Date;
  endDate: Date;
  location?: string;
  description?: string;
};

export const AddToCalendarButton: FC<Props> = ({
  title,
  startDate,
  endDate,
  location,
  description,
}) => {
  const intl = useIntl();
  const googleLink = 'https://calendar.google.com/calendar/render?action=TEMPLATE&'.concat([
    `text=${ encodeURI(title) }`,
    `dates=${ encodeURI(`${ startDate.toISOString() }-${ endDate.toISOString() }`) }`,
    ...description ? [`details=${ encodeURI(description) }`] : [],
    ...location ? [`location=${ encodeURI(location) }`] : [],
  ].join('&'));
  const outlookLink = 'https://outlook.live.com/calendar/0/action/compose?allday=false&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&'.concat([
    `subject=${ encodeURI(title) }`,
    `startdt=${ encodeURI(startDate.toISOString()) }`,
    `enddt=${ encodeURI(endDate.toISOString()) }`,
    ...description ? [`body=${ encodeURI(description) }`] : [],
    ...location ? [`location=${ encodeURI(location) }`] : [],
  ].join('&'));
  const icsContent = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `DTSTART:${ format(startDate, 'yyyyMMdd\'T\'HHmmss\'Z\'') }`,
    `DTEND:${ format(endDate, 'yyyyMMdd\'T\'HHmmss\'Z\'') }`,
    `SUMMARY:${ title }`,
    description ? [`DESCRIPTION:${ description }`] : [],
    location ? [`LOCATION:${ location }`] : [],
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');
  const icsBlob = useMemo(() => new Blob([icsContent], { type: 'text/calendar' }), [icsContent]);
  const [icsUrl, setIcsUrl] = useState('');

  useEffect(() => {
    const icsUrl = window.URL.createObjectURL(icsBlob);
    setIcsUrl(icsUrl);

    return () => {
      window.URL.revokeObjectURL(icsUrl);
      setIcsUrl('');
    };
  }, [icsBlob]);

  const menuItems = useMemo<MenuItem[]>(() => [
    {
      href: googleLink,
      label: intl.formatMessage({
        description: 'Label for link to add event to Google calendar.',
        defaultMessage: 'Google Calendar',
      }),
    },
    {
      href: outlookLink,
      label: intl.formatMessage({
        description: 'Label for link to add event to outlook calendar.',
        defaultMessage: 'Outlook',
      }),
    },
    {
      href: icsUrl,
      label: intl.formatMessage({
        description: 'Label for link to download event for apple Calendar app.',
        defaultMessage: 'Apple Calendar',
      }),
      download: `${ title }.ics`,
    },
  ], [googleLink, icsUrl, intl, outlookLink, title]);

  return (
    <PopoverMenu
      buttonVariant="tertiary"
      buttonLabel={ intl.formatMessage({
        description: 'Label for button that toggles a menu containing links to add an event via several calendar providers.',
        defaultMessage: 'Add to calendar',
      }) }
      items={ menuItems }
      getItemKey={ item => item.href }
      renderItem={ item => (
        <a
          css={ theme => ({
            width: '100%',
            padding: `${ theme.new.spacing[3] } ${ theme.new.spacing[6] }`,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',

            '&:hover': {
              textDecoration: 'none',
            },

            '&:focus-visible': {
              outline: 'none',
            },

            '::before': {
              content: '\'\'',
              zIndex: -1,
              display: 'block',
              top: theme.new.spacing[1],
              left: theme.new.spacing[1],
              bottom: theme.new.spacing[1],
              right: theme.new.spacing[1],
              borderRadius: theme.new.borderRadius.standard,
              position: 'absolute',
            },

            '&:hover::before': {
              background: theme.new.palette.grey[100].main,
            },

            '&:focus-visible::before': {
              outline: `1px solid ${ theme.new.palette.grey[300].main }`
            },
          }) }
          href={ item.href }
          { ...item.download ? { download: item.download } : {} }
          target="_blank"
          rel="noreferrer"
        >
          { item.label }
        </a>
      ) }
    />
  );
};

type MenuItem = {
  href: string;
  label: string;
  download?: string;
};
