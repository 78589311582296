import { FC } from 'react';
import { Badge } from '@ourpeople/shared/Core/Component/Content/Badge/Badge';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { FormattedMessage } from 'react-intl';
import { PresentationIcon } from '@ourpeople/shared/Core/Component/Content/PresentationIcon/PresentationIcon';
import StarIcon from '@ourpeople/shared/Asset/Icon/figma/star-06.svg';

export const NewFeatureBadge: FC = () => (
  <Badge
    variant="badge-colour"
    colour="primary"
    label={
      <Stack
        gap={ 1 }
        noWrap
      >
        <FormattedMessage
          description="Badge indicating new feature"
          defaultMessage="NEW!"
        />
        <PresentationIcon
          IconComponent={ StarIcon }
          size={ 4 }
        />
      </Stack>
    }
  />
);
