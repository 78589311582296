import { FC, useCallback, useState } from 'react';

import { useSsoProviderDetails } from '../../hooks/useSsoProviderDetails';
import { SsoProvider } from '../../model/SsoProvider';
import { Select } from '../../../stories/components/Select/Select';
import { SsoButton } from '../SsoButton/SsoButton';
import { DropdownItem } from '../../../stories/components/DropdownItem/DropdownItem';
import { DropdownItemChevron } from '../DropdownItemChevron/DropdownItemChevron';

type Props = {
  issuerProviders: SsoProvider[];
};

export const SsoIssuerButton: FC<Props> = ({
  issuerProviders,
}) => {
  const { lookupProviderDetails } = useSsoProviderDetails();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const issuerDetails = lookupProviderDetails(issuerProviders[0])?.issuer;
  const [navigating, setNavigating] = useState(false);

  const whenDropdownItemClicked = useCallback(
      (url: string) => {
          if (navigating) {
              return;
          }

          setNavigating(true);
          window.location.href = url;
      },
      [navigating],
  );

  if (issuerProviders.length < 1) {
    return <></>;
  }

  return issuerProviders.length === 1
    ? (
      <SsoButton
        key={ issuerDetails.issuer }
        url={ issuerProviders[0].url }
        name={ issuerDetails.name }
        Logo={ issuerDetails.logo }
        colour={ issuerDetails.colour }
      />
    )
    : (
      <Select
        disabled={navigating}
        align="start"
        open={ dropdownOpen }
        onOpenChange={ setDropdownOpen }
        selectedItems={ [] }
        onChange={ () => { /* noop */ } }
        options={ issuerProviders }
        matchWidth={ true }
        renderOption={ (issuerProvider) => {
          const providerDetails = lookupProviderDetails(issuerProvider);
          return providerDetails && (
            <>
              <DropdownItem
                disabled={navigating}
                text={ providerDetails.name }
                onClick={ () => whenDropdownItemClicked(issuerProvider.url) }
                endAdornment={
                  <DropdownItemChevron/>
                }
              />
            </>
          );
        } }
      >
        <div>
          <SsoButton
            disabled={navigating}
            key={ issuerDetails.issuer }
            url=""
            name={ issuerDetails.name }
            Logo={ issuerDetails.logo }
            colour={ issuerDetails.colour }
          />
        </div>
      </Select>
    );
};
