import { FC } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { PagingMenuHeader } from '../PagingMenuHeader/PagingMenuHeader';
import { StyledPagingMenuList } from './style';
import { PagingMenuListItem } from '../PagingMenuListItem/PagingMenuListItem';
import { PagingMenuFooter } from '../PagingMenuFooter/PagingMenuFooter';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import StarsIcon from 'op-storybook/lib/assets/icon/figma/stars-02.svg';
import { Prompt } from '../AiPromptMenu/AiPromptMenu';
import { StyledFooterButton } from './style';

type Props = {
  headerText: string;
  prompts: Prompt[];
  onPromptSelected: (prompt: Prompt) => void;
  userPromptLabel: string;
  onUserPromptSelected: () => void;
};

export const AiPromptList: FC<Props> = ({
  headerText,
  prompts,
  onPromptSelected,
  userPromptLabel,
  onUserPromptSelected,
}) => (
  <>
    <PagingMenuHeader>
      { headerText }
    </PagingMenuHeader>
    <StyledPagingMenuList>
      { prompts.map(prompt => (
        <PagingMenuListItem
          key={ prompt.value }
          label={ prompt.label }
          onClick={ () => onPromptSelected(prompt) }
        />
      )) }
    </StyledPagingMenuList>
    <StyledFooterButton onClick={ onUserPromptSelected }>
      <PagingMenuFooter>
        <Stack gap={ 4 }>
          <PresentationIcon IconComponent={ StarsIcon }/>
          <span>
            { userPromptLabel }
          </span>
        </Stack>
      </PagingMenuFooter>
    </StyledFooterButton>
  </>
);
