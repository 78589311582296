import {
  CSSProperties,
  FC,
  ForwardRefExoticComponent,
  MouseEventHandler,
  RefAttributes, RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { UniqueIdGenerator } from '../../../../Common/Utility';

type Props = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  ToggleComponent: ForwardRefExoticComponent<Omit<{
    popovertarget: string;
    onClick: MouseEventHandler
  }, 'ref'> & RefAttributes<HTMLButtonElement>>;
  ContentComponent: ForwardRefExoticComponent<Omit<{
    id: string;
    popover: 'auto' | 'manual';
    className?: string;
    style?: CSSProperties;
    onToggle: () => void;
  }, 'ref'> & RefAttributes<HTMLElement>>;
};

export const Popover: FC<Props> = ({
  open = false,
  onOpenChange,
  ToggleComponent,
  ContentComponent,
}) => {
  const [positionInitialised, setPositionInitialised] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [position, setPosition] = useState<'above' | 'below'>('below');
  const [toggleRectangle, setToggleRectangle] = useState<Omit<DOMRect, 'toJSON'>>({
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    bottom: 0,
    right: 0,
  });
  const toggleRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLElement>();
  const id = useMemo(() => (
    UniqueIdGenerator.generate()
  ), []);

  const onLocalOpenChange = useCallback((open: boolean) => {
    onOpenChange && onOpenChange(open);
  }, [onOpenChange]);

  useEffect(() => {
    if (!open) {
      popoverRef.current?.showPopover();
    } else {
      popoverRef.current?.hidePopover();
    }
  }, [open, popoverRef]);

  const onToggle = useCallback(() => {
    const clientRectangle = toggleRef.current?.getBoundingClientRect();
    clientRectangle && setToggleRectangle(clientRectangle);
    onLocalOpenChange(true);
  }, [onLocalOpenChange]);

  const contentRef: RefCallback<HTMLElement> = node => {
    if (!node) {
      return;
    }

    popoverRef.current = node;
    const onToggle = (event) => {
      if (event.newState === event.oldState) {
        return;
      }

      if (event.newState === 'closed') {
        setPositionInitialised(false);
        setPosition('below');
        return;
      }

      const targetRect = event.target.getBoundingClientRect();
      setContentHeight(targetRect.height);
      if (targetRect.bottom > document.documentElement.clientHeight) {
        setPosition('above');
      }

      setPositionInitialised(true);
    };

    node.addEventListener('toggle', onToggle);
  };

  return (
    <>
      <ToggleComponent
        ref={ toggleRef }
        onClick={ onToggle }
        popovertarget={ id }
      />
      <ContentComponent
        id={ id }
        ref={ contentRef }
        popover="auto"
        style={ {
          '--toggle-left': `${ toggleRectangle.left }px`,
          '--toggle-top': `${ toggleRectangle.top }px`,
          '--toggle-right': `${ toggleRectangle.right }px`,
          '--toggle-bottom': `${ toggleRectangle.bottom }px`,
          '--toggle-width': `${ toggleRectangle.width }px`,
          '--toggle-height': `${ toggleRectangle.height }px`,
        } }
        css={ {
          border: 'none',
          padding: 0,
          opacity: positionInitialised ? 1 : 0,
          margin: `calc(${ position === 'below' ? 'var(--toggle-bottom) +' : `-${ contentHeight }px + var(--toggle-top) -` } ${ MENU_OFFSET }) auto var(--toggle-left) auto`,
          width: 'var(--toggle-width)',
        } }
      />
    </>
  );
};

const MENU_OFFSET = '8px';
