import { ComponentProps, JSX, JSXElementConstructor, Ref, useMemo, RefObject, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import * as RadTabs from '@radix-ui/react-tabs';

import { Typography } from '../../../stories/components/Typography/Typography';
import { Badge } from '../../../stories/components/Badge/Badge';
import { StyleBuilder } from '../../model/StyleBuilder/StyleBuilder';

type Props<C extends keyof JSX.IntrinsicElements | JSXElementConstructor<never> = 'button'> = {
  innerRef?: Ref<HTMLButtonElement>;
  context: 'page' | 'section';
  active: boolean;
  value: string;
  label: string;
  count?: number;
  newBadge?: boolean;
  component?: C;
  className?: string;
  ref?: RefObject<HTMLButtonElement>;
  children?: ReactNode[];
  center?: boolean;
} & Omit<ComponentProps<C>, 'context' | 'active' | 'value' | 'label' | 'count' | 'newBadge' | 'component' | 'innerRef' | 'children'>;

// Type becomes too complex for the compiler when replacing this with an unknown or never
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Tab = <C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any> = 'button'>({
  innerRef,
  context = 'page',
  active,
  value,
  label,
  count,
  newBadge,
  component,
  center = false,
  ...componentProps
}: Props<C>) => {
  const intl = useIntl();
  const styles = useMemo(() => buildStyles({ context, center }), [context, center]);
  const RequiredComponent = component || 'button';
  const triggerProps = useMemo(() => ({
    ...componentProps,
    ref: innerRef,
    css: styles.trigger,
  }), [styles.trigger, innerRef, componentProps]);

  return (
    <RadTabs.Trigger
      value={ value }
      asChild
    >
      <RequiredComponent { ...triggerProps }>
        { newBadge && (
          <Badge
            variant="badge-colour"
            colour={ context === 'page' ? 'primary' : 'grey' }
            label={ intl.formatMessage({
              description: '"New" indicator used in tabs.',
              defaultMessage: 'NEW',
            }) }
          />
        ) }
        <Typography
          size="sm"
          weight="semibold"
          palette={ {
            colour: active
              ? context === 'page'
                ? 'primary'
                : 'grey'
              : 'grey',
            intensity: active ? 700 : 500,
          } }
          { ...center ? { css: { textAlign: 'center' } } : {} }
          transition
        >
          { label }
        </Typography>
        { count !== undefined && (
          <Badge
            variant="pill-colour"
            colour={ context === 'page' ? 'primary' : 'grey' }
            label={ String(count) }
          />
        ) }
      </RequiredComponent>
    </RadTabs.Trigger>
  );
};

type StyleProps = {
  context: Props['context'];
  center: boolean;
};

const buildStyles: StyleBuilder<StyleProps> = ({ context, center }) => ({
  trigger: theme => ({
    borderRadius: 0,
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: context === 'page' ? 'flex-end' : 'center',
    gap: theme.new.spacing[1],
    ...context === 'page'
      ? {
        padding: `0 ${ theme.new.spacing[1] } ${ theme.new.spacing[3] } ${ theme.new.spacing[1] }`,
      }
      : {
        padding: `${ theme.new.spacing[2] } ${ theme.new.spacing[3] }`,
      },
    ...center
      ? { justifyContent: 'center', textAlign: 'center' }
      : {}
  }),
})
