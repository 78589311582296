import {FC, useEffect} from 'react';
import {COMMAND_PRIORITY_EDITOR, CONTROLLED_TEXT_INSERTION_COMMAND, PASTE_COMMAND} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

type Props = {
  onPaste: (value: string) => string;
};

export const OnPastePlugin: FC<Props> = ({
  onPaste,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        const pastedText = event.clipboardData?.getData('Text');
        if (pastedText === undefined) {
          return false;
        }

        const updatedText = onPaste(pastedText);
        if (updatedText === pastedText) {
          return false;
        }

        editor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, updatedText);

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor, onPaste]);

  return null;
};
