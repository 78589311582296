import { ComponentProps, JSX, JSXElementConstructor } from 'react';

import { ButtonBase } from '../../../stories/components/ButtonBase/ButtonBase';

type BaseComponent = (keyof JSX.IntrinsicElements) | JSXElementConstructor<never>;

export const BreadcrumbButton = <C extends BaseComponent = 'button'>({
  padding = 'standard',
  ...props
}: ComponentProps<typeof ButtonBase<C>>): React.JSX.Element => (
  <ButtonBase
    padding={ padding }
    size="sm"
    variant="tertiary-dark"
    colour="grey"
    css={ {
      whiteSpace: 'nowrap',
      fontSize: '14px',
    } }
    { ...props as ComponentProps<typeof ButtonBase<C>> }
  />
);
