import React, { FC } from 'react';
import { TooltipProps } from '@mui/material';

import { PresentationIcon, Tooltip } from '..';
import BulbIcon from '../../../Assets/img/icons/streamline/bulb-1.svg';

export const HintTooltip: FC<Omit<TooltipProps, 'children'>> = props => {
  return (
    <Tooltip { ...props }>
      <span>
        <PresentationIcon
          IconComponent={ BulbIcon }
          size="medium"
        />
      </span>
    </Tooltip>
  );
}
