import { FC, Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useIntl } from 'react-intl';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { $isListNode, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND } from '@lexical/list';
import * as Popover from '@radix-ui/react-popover';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';
import UlIcon from 'op-storybook/lib/assets/icon/figma/dotpoints-01.svg';
import HeadingIcon from 'op-storybook/lib/assets/icon/figma/heading-01.svg';
import BoldIcon from 'op-storybook/lib/assets/icon/figma/bold-01.svg';
import ItalicsIcon from 'op-storybook/lib/assets/icon/figma/italic-01.svg';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { SvgComponent } from '../../../../../Common/Model';

type Props = {
  open: boolean;
  innerRef?: Ref<HTMLDivElement>;
};

export const FloatingToolbarPlugin: FC<Props> = ({
  open,
  innerRef,
}) => {
  const [editor] = useLexicalComposerContext();
  const localisedBlockFormattingOptions = useLocalisedBlockFormattingOptions();
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const whenBlockTypeChanged = useCallback((blockType: string) => {
    const supportedType = (
      localisedBlockFormattingOptions.find(option => option.id === blockType) ||
      localisedBlockFormattingOptions[0]
    );

    setBlockType(supportedType.id);
  }, [localisedBlockFormattingOptions]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root'
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          whenBlockTypeChanged(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          whenBlockTypeChanged(type);
        }
      }

      setBlockType($isListNode(element) ? 'ul' : $isHeadingNode(element) ? 'h3' : 'paragraph');
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
    }
  }, [editor, whenBlockTypeChanged]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LOW_PRIORITY,
      ),
    );
  }, [editor, updateToolbar]);

  const formatParagraph = useCallback(() => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
      setBlockType('paragraph');
    }
  }, [blockType, editor]);

  const formatHeading = useCallback(() => {
    if (blockType !== 'h3') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h3'));
        }
      });
      setBlockType('h3');
    } else {
      formatParagraph();
    }
  }, [blockType, editor, formatParagraph]);

  const formatBulletList = useCallback(() => {
    if (blockType === 'ul') {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    }
  }, [blockType, editor]);

  return (
    <Popover.Root
      open={ open }
    >
      <Popover.Anchor asChild>
        <div
          css={ {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          } }
        />
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          side="top"
          sideOffset={ 4 }
          align="center"
          onOpenAutoFocus={ event => {
            event.preventDefault();
          } }
          onCloseAutoFocus={ event => {
            event.preventDefault();
          } }
          css={ theme => ({
            filter: 'drop-shadow( 0 2px 5px hsla(0, 0%, 0%, 0.05)) drop-shadow( 0 0 1px hsla(0, 0%, 0%, 0.1))',
            width: 'var(--radix-popper-anchor-width)',
            display: 'flex',
            justifyContent: 'center',

            'span > svg': {
              height: theme.new.spacing[2],
              width: theme.new.spacing[2],
              fill: theme.new.basePalette.white.main,
            },
          }) }
        >
          <div
            { ...innerRef ? { ref: innerRef } : {} }
            css={ theme => ({
              borderRadius: theme.new.borderRadius.standard,
              backgroundColor: theme.new.basePalette.white.main,
              padding: `${ theme.new.spacing[1] } ${ theme.new.spacing[2] }`,
              width: 'min-content',
            }) }
          >
            <Stack gap={ 1 } noWrap>
              <Button
                variant="tertiary-grey"
                padding="uniform"
                onClick={ () => formatHeading() }
                css={ theme => ({
                  ...blockType === 'h3'
                    ? { background: theme.new.palette.grey['100'].main }
                    : {},
                }) }
              >
                <PresentationIcon
                  IconComponent={ HeadingIcon }
                  size={ 4 }
                />
              </Button>
              <Button
                variant="tertiary-grey"
                padding="uniform"
                onClick={ () => formatBulletList() }
                css={ theme => ({
                  ...blockType === 'ul'
                    ? { background: theme.new.palette.grey['100'].main }
                    : {},
                }) }
              >
                <PresentationIcon
                  IconComponent={ UlIcon }
                  size={ 4 }
                />
              </Button>
              <Button
                variant="tertiary-grey"
                padding="uniform"
                onClick={ () => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                } }
                css={ theme => ({
                  ...isBold
                    ? { background: theme.new.palette.grey['100'].main }
                    : {},
                }) }
              >
                <PresentationIcon
                  IconComponent={ BoldIcon }
                  size={ 4 }
                />
              </Button>
              <Button
                variant="tertiary-grey"
                padding="uniform"
                onClick={ () => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                } }
                css={ theme => ({
                  ...isItalic
                    ? { background: theme.new.palette.grey['100'].main }
                    : {},
                }) }
              >
                <PresentationIcon
                  IconComponent={ ItalicsIcon }
                  size={ 4 }
                />
              </Button>
            </Stack>
            <Popover.Arrow />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const LOW_PRIORITY = 1;

export type BlockType = 'h3' | 'paragraph' | 'ul';

export type FormattingOption = LocalisedString<BlockType> & { IconComponent: SvgComponent };

export const useLocalisedBlockFormattingOptions = (): FormattingOption[] => {
  const intl = useIntl();
  return useMemo<FormattingOption[]>(() => [
    {
      id: 'h3',
      localisation: intl.formatMessage({
        description: 'Label for heading text style in rich text editor.',
        defaultMessage: 'Heading',
      }),
      IconComponent: HeadingIcon,
    },
    {
      id: 'ul',
      localisation: intl.formatMessage({
        description: 'Label for bulleted list text style in rich text editor.',
        defaultMessage: 'Bulleted list',
      }),
      IconComponent: UlIcon,
    },
  ], [intl]);
};

