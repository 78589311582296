import styled from '@emotion/styled';
import { Select } from '@mui/material';

export const StyledOptionLabel = styled.span`
  margin-bottom: -3px;
`;

export const StyledSelect = styled(Select)`
  ::after, ::before {
    display: none;
  }
`;
