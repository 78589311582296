import { FC, SVGProps, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Stack } from '../Stack/Stack';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { StyledSsoButton } from './style';

type Props = {
  url: string;
  name: string;
  Logo: FC<SVGProps<SVGSVGElement>>;
  colour?: string;
  disabled?: boolean;
};

export const SsoButton: FC<Props> = ({
  url,
  name,
  Logo,
  colour,
  disabled,
}) => {
  const whenButtonClicked = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        event.preventDefault();
      }
    },
    [disabled],
  );

  return (
    <StyledSsoButton
      variant="secondary-grey"
      component="a"
      href={ url }
      color={ colour }
      disabled={ disabled }
      onClick={ whenButtonClicked }
    >
      <Stack gap={ 4 }>
        <PresentationIcon IconComponent={ Logo }/>
        <FormattedMessage
          description="Label for SSO sign-in button."
          defaultMessage="Sign in with { ssoClient }"
          values={ { ssoClient: name } }
        />
      </Stack>
    </StyledSsoButton>
  );
}
