import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import RightArrowIcon from '../../../../Asset/Icon/Streamline/arrow-right-1.svg';
import { StyledTemplatePrompts } from './style';
import { TemplatePrompt } from '../AiUserPrompt/AiUserPrompt';

type Props = {
  templatePrompts: TemplatePrompt[];
  onTemplatePromptSelected: (templatePrompt: TemplatePrompt) => void;
  truncate?: {
    maxTemplates: number;
    onShowMoreClicked: () => void;
  };
};

export const AiTemplatePrompts: FC<Props> = ({
  templatePrompts,
  onTemplatePromptSelected,
  truncate,
}) => {
  const visibleTemplatePrompts = useMemo(() => (
    templatePrompts.slice(0, truncate?.maxTemplates || templatePrompts.length)
  ), [templatePrompts, truncate]);

  return (
    <StyledTemplatePrompts>
      { visibleTemplatePrompts.map(templatePrompt => (
        <li
          key={ templatePrompt.key }
        >
          <Button
            variant="secondary"
            onClick={ () => onTemplatePromptSelected(templatePrompt) }
          >
            { templatePrompt.label }
          </Button>
        </li>
      )) }
      { truncate && templatePrompts.length > truncate.maxTemplates && (
        <li>
          <Button
            variant="secondary"
            onClick={ truncate.onShowMoreClicked }
          >
            <Stack noWrap>
              <FormattedMessage
                description="Label for button to show more prompt templates in OurPeople AI menu."
                defaultMessage="More templates"
              />
              <PresentationIcon
                IconComponent={ RightArrowIcon }
                size={ 4 }
              />
            </Stack>
          </Button>
        </li>
      ) }
    </StyledTemplatePrompts>
  );
};
