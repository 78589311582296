import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';

import { StyledLoadingContainer } from './style';
import { RequestState } from '../../../../../src/react/Models/RequestState';
import { PresentationIcon } from '../../Content/PresentationIcon/PresentationIcon';
import ErrorIcon from '../../../../Asset/Icon/Streamline/alert-circle.svg';

type Props = {
  fetchState: RequestState;
  onClick: () => void;
};

export const ListLoadingIndicator: FC<Props> = ({
  fetchState,
  onClick,
}) => (
  <StyledLoadingContainer
    visible={ [RequestState.FETCHING, RequestState.FAILED].includes(fetchState) }
    { ...fetchState === RequestState.FAILED ? { onClick } : {} }
  >
    {
      fetchState === RequestState.FAILED
        ? (
          <>
            <PresentationIcon
              IconComponent={ ErrorIcon }
              color="error.600.main"
            />
            <FormattedMessage
              description="Message displayed when loading more items in a list fails"
              defaultMessage="Something went wrong, { touchDevice, select, true {tap} other {click} } to try again."
              values={ {
                touchDevice: window.matchMedia('(hover: none)').matches,
              } }
            />
          </>
        )
        : (
          <>
            <CircularProgress
              size={ 24 }
              color="secondary"
            />
            <FormattedMessage
              description="Message displayed when loading more items in a list"
              defaultMessage="Loading more…"
            />
          </>
        )
    }
  </StyledLoadingContainer>
);
