import { FC, MouseEventHandler, ReactNode, useMemo } from 'react';

import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';

type Props = {
  disabled?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  padding?: 'md' | 'sm';
  children?: ReactNode;
};

export const MenuItem: FC<Props> = ({
  disabled = false,
  selected = false,
  onClick,
  className,
  padding,
  children
}) => {
  const styles = useMemo(() => buildStyles({ disabled, selected, padding }), [disabled, selected, padding]);

  return (
    <button
      css={ styles.menuItem }
      disabled={ disabled }
      onClick={ onClick }
      className={ className }
    >
      <div css={ styles.menuItemContents }>
        { children }
      </div>
    </button>
  );
};

type StyleBuilderProps = {
  disabled: boolean;
  selected: boolean;
  padding: 'md' | 'sm';
};

const buildStyles: StyleBuilder<StyleBuilderProps> = ({ disabled, selected, padding }) => ({
  menuItem: theme => ({
    width: '100%',
    background: 'transparent',
    border: 'none',
    padding: padding === 'sm'
      ? `${ theme.new.spacing[1] } ${ theme.new.spacing[2] }`
      : `${ theme.new.spacing[2] } ${ theme.new.spacing[4] }`,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',

    ...selected
      ? {
        '>div': {
          background: theme.new.palette.grey[100].main,
        },
      }
      : {
        ...disabled
          ? {
            pointerEvents: 'none',
          }
          : {
            ':hover, :focus-visible': {
              outline: 'none',

              '>div': {
                background: theme.new.palette.grey[50].main,
              },
            },
          },
      },
  }),
  menuItemContents: theme => ({
    padding: padding === 'sm' ? theme.new.spacing[2] : theme.new.spacing[4],
    borderRadius: padding === 'sm' ? theme.new.borderRadius.standard : theme.new.borderRadius.large,
    flexGrow: 1,
    width: '100%',
  }),
});
