import { FC, useEffect, useState } from 'react';
import { Overlay } from 'op-storybook/lib/components/Overlay/Overlay';
import { Fade, Slide } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { NavItem } from '@ourpeople/shared/Core/Component/Navigation/NavItem/NavItem';
import MenuIcon from '@ourpeople/shared/Asset/Icon/figma/menu-02.svg';
import CloseIcon from '@ourpeople/shared/Asset/Icon/figma/x-close.svg';
import { PresentationIcon } from '@ourpeople/shared/Core/Component/Content/PresentationIcon/PresentationIcon';

import {
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledLogo,
  StyledMenuBar,
  StyledMenuContainer,
  StyledMobileNavigation
} from './style';
import { ConfiguredSidebar } from '../ConfiguredSidebar/ConfiguredSidebar';
import { useEnvironmentSettings } from '../../Hook';
import { useScrollLock } from '../../../New/Common/Hook/useScrollLock';

export const MobileNavigation: FC = () => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const { smallLogoUrl = '', name: spaceName } = useEnvironmentSettings();
  useScrollLock(open);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <>
      <StyledMenuBar>
        <Stack>
          <StyledLogo
            src={ smallLogoUrl }
            role="presentation"
            alt={ spaceName }
          />
          <StackEnd>
            <NavItem
              onClick={ () => setOpen(open => !open) }
              label={ intl.formatMessage({
                description: 'Label for menu toggle button in mobile navigation',
                defaultMessage: 'Open menu',
              }) }
              IconComponent={ MenuIcon }
              iconOnly
            />
          </StackEnd>
        </Stack>
      </StyledMenuBar>
      { createPortal(
        (
          <StyledMobileNavigation
            open={ open }
          >
            <Overlay
              open={ open }
              onClick={ () => setOpen(false) }
            />
            <StyledCloseButtonContainer onClick={ () => setOpen(false) }>
              <Fade in={ open }>
                <StyledCloseButton>
                  <PresentationIcon IconComponent={ CloseIcon }/>
                </StyledCloseButton>
              </Fade>
            </StyledCloseButtonContainer>
            <Slide
              in={ open }
              direction="right"
              timeout={ 100 }
            >
              <StyledMenuContainer>
                <ConfiguredSidebar mobile/>
              </StyledMenuContainer>
            </Slide>
          </StyledMobileNavigation>
        ),
        document.body,
      ) }
    </>
  );
};
