import { FC, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb as SharedBreadcrumb } from 'op-storybook/stories/components/Breadcrumb/Breadcrumb';
import { ProgressCircle } from 'op-storybook/stories/components/ProgressCircle/ProgressCircle';

import { BreadcrumbItem } from '../PageHeader/PageHeader';

type Props = {
  items: (BreadcrumbItem | null)[];
  includeBackground?: boolean;
};

export const Breadcrumb: FC<Props> = ({
  items,
  includeBackground = false,
}) => {
  const history = useHistory();

  const getMenuItemProps = useCallback((item: BreadcrumbItem | null) => (
    item === null
      ? {
        text: '',
        onClick: () => { /* no-op */ },
      }
      : {
        text: item.title,
        onClick: () => item.link && history.push(item.link),
      }
  ), [history]);

  return (
    <SharedBreadcrumb
      items={ items }
      homeButtonProps={ homeButtonProps }
      getItemKey={ getItemKey }
      getButtonProps={ getButtonProps }
      getMenuItemProps={ getMenuItemProps }
      includeBackground={ includeBackground }
    />
  );
};

const getItemKey = (item: BreadcrumbItem | null, index: number) => item ? item.link || item.title : String(index);
const getButtonProps = (item: BreadcrumbItem | null) => (
  item === null
    ? {
      children: <ProgressCircle size="xs" />,
    }
    : {
      children: item.title,
      ...(item.link ? {
          component: Link,
          to: item.link,
        } : {}),
    }
);

const homeButtonProps = {
  component: Link,
  to: '/',
};
