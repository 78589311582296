import {FC, PropsWithChildren} from 'react';
import { TooltipProps } from '@mui/material';

import { Tooltip } from '../Tooltip/Tooltip';

type Props = TooltipProps & {
  active: boolean;
};

export const ConditionalTooltip: FC<PropsWithChildren<Props>> = ({
  active,
  children,
  ...tooltipProps
}) => (
  active
    ? (
      <Tooltip { ...tooltipProps }>
        <div>{ children }</div>
      </Tooltip>
    )
    : children
);
