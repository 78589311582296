import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { SpeedDialAction } from '@mui/material';

import { Fab } from '../../../Core/Component/Input/Fab/Fab';
import { PresentationIcon } from '../../../Core/Component/Content/PresentationIcon/PresentationIcon';
import NewFileIcon from '../../../Asset/Icon/Streamline/common-file-empty.svg';
import NewFolderIcon from '../../../Asset/Icon/Streamline/folder-empty.svg';

type Props = {
  onNewFolderClicked: () => void;
  onNewFileClicked: () => void;
};

export const FilesFab: FC<Props> = ({
  onNewFolderClicked,
  onNewFileClicked,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const intl = useIntl();
  const close = useCallback(() => setOpen(false), []);

  const whenNewFolderClicked = useCallback(() => {
    onNewFolderClicked();
  }, [onNewFolderClicked]);

  const whenNewFileClicked = useCallback(() => {
    onNewFileClicked();
  }, [onNewFileClicked]);

  return (
    <Fab
      ariaLabel={ intl.formatMessage({
        description: 'Label for fab button on files page',
        defaultMessage: 'Add a file or folder'
      }) }
      open={ open }
      onClose={ close }
      onClick={ () => setOpen(open => !open) }
    >
      <SpeedDialAction
        icon={ <PresentationIcon IconComponent={ NewFolderIcon }/> }
        tooltipOpen
        tooltipTitle={ intl.formatMessage({
          description: 'Label for new folder option in FAB menu on files page at mobile resolutions.',
          defaultMessage: 'New folder'
        }) }
        onClick={ whenNewFolderClicked }
      />
      <SpeedDialAction
        icon={ <PresentationIcon IconComponent={ NewFileIcon }/> }
        tooltipOpen
        tooltipTitle={ intl.formatMessage({
          description: 'Label for new file option in FAB menu on files page at mobile resolutions.',
          defaultMessage: 'New file'
        }) }
        onClick={ whenNewFileClicked }
      />
    </Fab>
  );
};
