import {FC} from 'react';

import {useSsoProviderDetails} from '../../hooks/useSsoProviderDetails';
import {Stack} from '../Stack/Stack';
import {SsoProvider} from '../../model/SsoProvider';
import {SsoIssuerButton} from '../SsoIssuerButton/SsoIssuerButton';

type Props = {
  providers: SsoProvider[];
};

export const SsoButtons: FC<Props> = ({
  providers,
}) => {
  const {groupProvidersByIssuer, lookupProviderDetails} = useSsoProviderDetails();
  const groupedProvidersByIssuer = groupProvidersByIssuer(providers);

  return (
    <Stack
      direction="column"
      gap={ 4 }
    >
      { Object.values(groupedProvidersByIssuer).map(issuerProviders => {
        if (issuerProviders.length < 1) {
          return;
        }

        const issuerDetails = lookupProviderDetails(issuerProviders[0])?.issuer;

        return issuerDetails && ((
          <SsoIssuerButton
              issuerProviders={ issuerProviders }
          />
        ));
      }) }
    </Stack>
  );
};
