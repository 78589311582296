import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { PresentationIcon } from '@ourpeople/shared/Core/Component/Content/PresentationIcon/PresentationIcon';
import StarsIcon from 'op-storybook/lib/assets/icon/figma/stars-02.svg';
import { Badge } from '@ourpeople/shared/Core/Component/Content/Badge/Badge';

type Props = {
  hideText?: boolean;
};

export const AiFeatureBadge: FC<Props> = ({
  hideText = false,
}) => (
  <Badge
    variant="badge-colour"
    colour="primary"
    label={
      <Stack
        gap={ 1 }
        noWrap
      >
        { !hideText && (
          <FormattedMessage
            description="Badge indicating AI feature in form/broadcast creation"
            defaultMessage="AI"
          />
        ) }
        <PresentationIcon
          IconComponent={ StarsIcon }
          size={ 4 }
        />
      </Stack>
    }
  />
);
