import { FC, ReactNode, useEffect } from 'react';
import {
  $getSelection,
  createCommand,
  DecoratorNode,
  DOMExportOutput, EditorConfig,
  LexicalCommand,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  TextNode
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Badge } from 'op-storybook/stories/components/Badge/Badge';

import { RichTextPlaceholderDefinition } from '../../../../../../src/react/Common/Model/RichTextPlaceholderDefinition';

interface SerialisedPlaceholderNode extends SerializedLexicalNode {
  type: 'placeholder';
  version: 1;
  substitution: string;
  label: string;
  placeholder: string;
}

type Props = {
  placeholders: RichTextPlaceholderDefinition[];
};

export const PlaceholderNodePlugin: FC<Props> = ({ placeholders }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      ADD_PLACEHOLDER_COMMAND,
      placeholder => {
        editor.update(() => {
          const selection = $getSelection();
          selection?.insertNodes([new PlaceholderNode(placeholder)]);
        });

        return true;
      },
      1,
    );
  }, [editor]);

  useEffect(() => {
    return editor.registerNodeTransform(TextNode, textNode => {
      const regex = /\[([A-Za-z._]+)]/gm;
      const match = regex.exec(textNode.getTextContent());

      if (!match) {
        return;
      }

      const [matchString, captureString] = match;
      const placeholder = placeholders.find(placeholder => placeholder.placeholderString === captureString);

      if (!placeholder) {
        return;
      }

      const matchIndex = textNode.getTextContent().indexOf(matchString);
      textNode.spliceText(matchIndex, matchString.length, '', true);
      const [firstTextNode] = textNode.splitText(matchIndex);

      if (firstTextNode) {
        const placeholderNode = new PlaceholderNode(placeholder);

        if (matchIndex === 0) {
          firstTextNode.insertBefore(placeholderNode, true);
        } else {
          firstTextNode.insertAfter(placeholderNode, true);
        }

        placeholderNode.selectNext(0, 0);
      } else {
        editor.dispatchCommand(ADD_PLACEHOLDER_COMMAND, placeholder);
      }
    });
  }, [editor, placeholders]);

  return null;
};

export class PlaceholderNode<P extends RichTextPlaceholderDefinition> extends DecoratorNode<ReactNode> {
  public static type = 'placeholder';
  private __placeholder: P;

  constructor(placeholder: P, key?: NodeKey) {
    super(key);
    this.__placeholder = placeholder;
  }

  static getType() {
    return PlaceholderNode.type;
  }

  public static clone<P extends RichTextPlaceholderDefinition>(node: PlaceholderNode<P>) {
    return new PlaceholderNode(node.getPlaceholder(), node.__key);
  }

  public static importJSON(jsonNode: SerialisedPlaceholderNode): PlaceholderNode<any> {
    return new PlaceholderNode<any>({
      localisedString: jsonNode.label,
      placeholderString: jsonNode.placeholder,
      substituteOnly: false,
      substitution: jsonNode.substitution,
    });
  }

  public updateDOM(_prevNode: unknown, _dom: HTMLElement, _config: EditorConfig): boolean {
    return false;
  }

  public exportJSON() {
    return {
      type: 'placeholder',
      version: 1,
      substitution: this.getSubstitution(),
      label: this.getLabel(),
      placeholder: this.getPlaceholderText(),
    };
  }

  public createDOM() {
    const container = document.createElement('span');
    container.style.display = 'inline-block';
    container.style.userSelect = 'none';
    return container;
  }

  public replace<N extends LexicalNode>(replaceWith: N, includeChildren?: boolean): N {
    return super.replace(replaceWith, includeChildren);
  }

  public exportDOM(): DOMExportOutput {
    const spanElement = document.createElement('span');
    spanElement.innerText = this.getPlaceholderText();

    return {
      element: spanElement,
    };
  }

  public isKeyboardSelectable(): boolean {
    return false
  }

  public isInline(): boolean {
    return true
  }

  public isIsolated(): boolean {
    return false
  }

  private getPlaceholder(): RichTextPlaceholderDefinition {
    const self = this.getLatest();
    return self.__placeholder;
  }

  public getLabel(): string {
    return this.getPlaceholder().localisedString;
  }

  public getPlaceholderText(): string {
    return `[${ this.getPlaceholder().placeholderString }]`;
  }

  public getSubstitution(): string {
    return this.getPlaceholder().substitution;
  }

  public getTextContent(): string {
    return this.getPlaceholderText();
  }

  public getTextContentSize(): number {
    return 0;
  }

  public decorate(): ReactNode {
    return (
      <Badge
        colour="grey"
        variant="badge-modern"
        label={ this.getLabel() }
        css={ { userSelect: 'none' } }
      />
    );
  }
}

export const ADD_PLACEHOLDER_COMMAND: LexicalCommand<RichTextPlaceholderDefinition> = createCommand('ADD_PLACEHOLDER_COMMAND');
