import { FC, useCallback } from 'react';
import { PresentationIcon } from '@ourpeople/shared/Core/Component/Content/PresentationIcon/PresentationIcon';

import { Flex, FlexPullRight } from '../../../../../Common/Component';
import { StyledCrossIcon, StyledSignature, StyledSignatureDate, StyledSignatureName } from './style';

type Props = {
  signedAt: Date | null;
  signature: string;
  onChange: (signedAt: Date | null) => void;
  disabled?: boolean;
};

export const Signature: FC<Props> = ({
  signedAt,
  signature,
  onChange,
  disabled = false,
}) => {
  const whenSignClicked = useCallback(() => {
    if (disabled) {
      return;
    }

    onChange(signedAt ? null : new Date());
  }, [disabled, onChange, signedAt]);

  return (
    <StyledSignature>
      <PresentationIcon
        size={ 10 }
        IconComponent={ StyledCrossIcon }
      />
      <div>
        <StyledSignatureName disabled={ disabled } onClick={ whenSignClicked }>
          { signedAt && signature }
        </StyledSignatureName>
        <hr/>
        <Flex>
          <FlexPullRight>
            <StyledSignatureDate>
              { signedAt && signedAt.toLocaleDateString(navigator.language, {
                weekday: 'short',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }) }
            </StyledSignatureDate>
          </FlexPullRight>
        </Flex>
      </div>
    </StyledSignature>
  )
};
