import { FC, useMemo, useState } from 'react';
import { Select } from 'op-storybook/stories/components/Select/Select';
import { DropdownItem } from 'op-storybook/stories/components/DropdownItem/DropdownItem';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon'
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ButtonBase } from 'op-storybook/stories/components/ButtonBase/ButtonBase';

import {
  BlockType,
  FormattingOption,
  useLocalisedBlockFormattingOptions
} from '../Hook/useLocalisedBlockFormattingOptions';
import { StyledOptionLabel } from './style';

type Props = {
  value: BlockType;
  onChange: (value: BlockType) => void;
};

export const BlockFormattingOptionSelect: FC<Props> = ({
  value,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const options = useLocalisedBlockFormattingOptions();
  const selectedItems = useMemo<FormattingOption[]>(() => {
    const selectedOption = options.find(option => option.id === value);

    return selectedOption ? [selectedOption] : [];
  }, [options, value]);

  return (
    <Select<FormattingOption>
      selectedItems={ selectedItems }
      onChange={ selectedItems => onChange(selectedItems[1].id) }
      css={ {
        zIndex: 1000,
      } }
      align="start"
      renderOption={ option => (
        <BlockFormattingOption
          key={ option.id }
          option={ option }
        />
      ) }
      open={ open }
      onOpenChange={ setOpen }
      options={ options }
    >
      <ButtonBase
        variant="tertiary-dark"
        colour="grey"
        size="sm"
      >
        <Stack>
          <PresentationIcon
            IconComponent={ selectedItems[0].IconComponent }
            size={ 4 }
          />
          <StyledOptionLabel>{ selectedItems[0].localisation }</StyledOptionLabel>
        </Stack>
      </ButtonBase>
    </Select>
  );
};

const BlockFormattingOption: FC<{ option: FormattingOption }> = ({
  option,
}) => (
  <DropdownItem
    text={ option.localisation }
    startAdornment={
      <PresentationIcon
        IconComponent={ option.IconComponent }
        size={ 4 }
      />
    }
  />
);
