import * as RadDropdownMenu from '@radix-ui/react-dropdown-menu';
import { FC, PropsWithChildren, ReactNode, JSX, useCallback, useRef, useEffect, useState } from 'react';

import { Card } from '../../../lib/components/Card/Card';
import { Stack } from '../../../lib/components/Stack/Stack';

type Props<T> = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedItems: T[];
  onChange: (selectedItems: T[]) => void;
  options: T[];
  renderOption: (option: T, Indicator: FC<PropsWithChildren>) => ReactNode;
  compare?: (optionA: T, optionB: T) => boolean;
  align?: 'start' | 'center' | 'end';
  matchWidth?: boolean;
  className?: string;
  disabled?: boolean;
};

export const Select = <T, >({
  open,
  onOpenChange,
  selectedItems,
  onChange,
  options,
  renderOption,
  compare = (optionA, optionB) => optionA === optionB,
  align = 'center',
  children,
  matchWidth,
  className,
  disabled,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const triggerRef = useRef<HTMLSpanElement>(null);
  const [triggerWidth, setTriggerWidth] = useState<number>(0);

  const whenCheckedChanged = useCallback((item: T, checked: boolean) => (
    onChange(
      checked
        ? selectedItems.concat(item)
        : selectedItems.filter(selectedItem => !compare(selectedItem, item))
    )
  ), [compare, onChange, selectedItems]);

  // Capture the width of the trigger element
  useEffect(() => {
    triggerRef.current && setTriggerWidth(triggerRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <RadDropdownMenu.Root
      open={ open }
      onOpenChange={ onOpenChange }
    >
      <RadDropdownMenu.Trigger
          asChild
          disabled={disabled}
      >
        <span ref={ triggerRef }>
          { children }
        </span>
      </RadDropdownMenu.Trigger>
      <RadDropdownMenu.Portal>
        <RadDropdownMenu.Content
          align={ align }
          sideOffset={ 4 }
          className={ className }
          css={ {
            width: matchWidth && triggerWidth
              ? `${ triggerWidth }px`
              : 'auto',
          } }
        >
          <Card>
            <Stack
              direction="column"
              gap={ 0 }
              css={ {
                maxHeight: '300px',
                overflowY: 'auto',
              } }
            >
              { options.map((option, optionIndex) => (
                <RadDropdownMenu.CheckboxItem
                  key={ optionIndex }
                  css={ {
                    ':focus-visible': {
                      outline: 'none',
                    },
                  } }
                  checked={ !!selectedItems.find(selectedItem => compare(selectedItem, option)) }
                  onCheckedChange={ checked => whenCheckedChanged(option, checked) }
                >
                  { renderOption(
                    option,
                    ({ children }) => (
                      <RadDropdownMenu.ItemIndicator>
                        { children }
                      </RadDropdownMenu.ItemIndicator>
                    ),
                  ) }
                </RadDropdownMenu.CheckboxItem>
              )) }
            </Stack>
          </Card>
        </RadDropdownMenu.Content>
      </RadDropdownMenu.Portal>
    </RadDropdownMenu.Root>
  );
}
