import { FC } from 'react';
import { TooltipProps } from '@mui/material';

import HelpIcon from '../../../Assets/img/icons/monochrome/help.svg';
import { Tooltip } from '../Tooltip/Tooltip';

export const HelpTooltip: FC<Omit<TooltipProps, 'children'>> = props => (
  <Tooltip
    arrow
    placement="top"
    { ...props }
  >
    <span><HelpIcon/></span>
  </Tooltip>
);
