import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  AsYouType,
  CountryCallingCode,
  CountryCode,
  getCountries,
  getCountryCallingCode,
  PhoneNumber
} from 'libphonenumber-js';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import {
  ValidationErrorHandler
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { InvalidPhoneNumberForRegion, ValidationError } from '../../../../../src/react/Common/Model/ValidationError';
import { StyledCallingCode, StyledCountryName, StyledMenu } from './style';
import { PresentationIcon } from '../../../../Core/Component/Content/PresentationIcon/PresentationIcon';
import DownArrowIcon from '../../../../Asset/Icon/Streamline/arrow-down-1.svg';
import { useDebounce } from '../../../../Core/Hook/useDebounce';
import { FieldButton } from '../../../../Core/Component/Input/FieldIconButton/FieldButton';
import { LabelledField } from '../../../../Core/Component/Input/LabelledField/LabelledField';

export interface CountryInformation {
  code: CountryCode;
  callingCode: CountryCallingCode;
  name: string;
}

export interface PhoneNumberFieldValue {
  countryCode: string;
  phoneNumber: string;
}

type Props = {
  value: PhoneNumberFieldValue;
  onChange: (value: PhoneNumberFieldValue, parsed?: PhoneNumber) => void;
  errors?: ValidationError[];
  customHandlers?: ValidationErrorHandler<any>[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  placeholder?: string;
  disabled?: boolean;
};

export const PhoneNumberField: FC<Props> = ({
  value,
  onChange,
  errors,
  customHandlers,
  placeholder,
  disabled = false,
}) => {
  const intl = useIntl();
  const [countryMenuOpen, setCountryMenuOpen] = useState<boolean>(false);
  const countryMenuButtonRef = useRef<HTMLButtonElement>(null);
  const [countryManuallySelected, setCountryManuallySelected] = useState<boolean>(false);
  const debouncedPhoneNumber = useDebounce(value.phoneNumber, 500);
  const acceptedPhoneNumber = useRef<string>();

  const whenValueChanged = useCallback(
    (value: PhoneNumberFieldValue) => {
      const parsed = parseValue(value);
      onChange(value, parsed.phoneNumber);
    },
    [onChange]
  );

  const updateCountryCode = useCallback((phoneNumber: string) => {
    if (countryManuallySelected) {
      return;
    }

    const {countryCode, phoneNumber: parsedPhoneNumber} = parseValue({
      countryCode: value.countryCode,
      phoneNumber,
    });

    if (countryCode && parsedPhoneNumber) {
      const formattedNumber = parsedPhoneNumber.formatNational();

      if (acceptedPhoneNumber.current === formattedNumber) {
        return;
      }

      acceptedPhoneNumber.current = formattedNumber;
      whenValueChanged({
        phoneNumber: formattedNumber,
        countryCode,
      });
    }
  }, [countryManuallySelected, value.countryCode, whenValueChanged]);

  useEffect(() => {
    updateCountryCode(debouncedPhoneNumber);
  }, [debouncedPhoneNumber, updateCountryCode]);

  const countryInformation = useMemo<CountryInformation[]>(() => {
    const displayNames = new Intl.DisplayNames(intl.locale, { type: 'region' });
    const getCountryInformation = (countryCode: CountryCode) => ({
      code: countryCode,
      callingCode: getCountryCallingCode(countryCode),
      name: displayNames.of(countryCode) || countryCode,
    });

    return getCountries()
      .map(getCountryInformation)
      .sort(sortCountriesByName);
  }, [intl.locale]);

  const invalidPhoneNumberError: ValidationErrorHandler<InvalidPhoneNumberForRegion> = useMemo(() => ({
    assert: (error): error is InvalidPhoneNumberForRegion => (
      error.type === 'invalidPhoneNumberForRegion'
    ),
    handle: error => intl.formatMessage({
      description: 'Error when phone number does not match expected region',
      defaultMessage: 'Invalid { countryName } phone number.'
    }, {
      countryName: countryInformation.find(country => country.code === error.metadata.region)?.name,
    }),
  }), [countryInformation, intl]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customPhoneNumberHandlers = useMemo<ValidationErrorHandler<any>[]>(() => ([
    ...(customHandlers || []),
    invalidPhoneNumberError,
  ]), [customHandlers, invalidPhoneNumberError]);

  const selectedCountryInformation = useMemo(() => (
    countryInformation.find(countryInformation => countryInformation.code === value.countryCode) || countryInformation[0]
  ), [countryInformation, value.countryCode]);

  return (
    <div css={ { position: 'relative' } }>
      <LabelledField
        label={ intl.formatMessage({
          description: 'Label for phone number field used in account registration and updating.',
          defaultMessage: 'Phone number',
        }) }
        fullWidth
        value={ value.phoneNumber }
        errors={ errors }
        disabled={ disabled }
        placeholder={ placeholder }
        onChange={ event => whenValueChanged({
          ...value,
          phoneNumber: event.currentTarget.value,
        }) }
        customHandlers={ customPhoneNumberHandlers }
        startAdornment={ {
          type: 'node',
          node: (
            <FieldButton
              onClick={ () => setCountryMenuOpen(true) }
              ref={ countryMenuButtonRef }
            >
              <Stack gap={ 1 }>
                +{ selectedCountryInformation.callingCode }
                <PresentationIcon
                  IconComponent={ DownArrowIcon }
                  size={ 3 }
                />
              </Stack>
            </FieldButton>
          ),
        } }
        type="tel"
        autoComplete="tel"
      />
      { countryMenuButtonRef.current && (
        <StyledMenu
          open={ countryMenuOpen }
          onClose={ () => setCountryMenuOpen(false) }
          anchorEl={ countryMenuButtonRef.current }
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'left',
          } }
          transformOrigin={ {
            vertical: 'top',
            horizontal: 'left',
          } }
        >
          { countryInformation.map(countryInformation => (
            <MenuItem
              key={ countryInformation.code }
              onClick={ () => {
                whenValueChanged({
                  ...value,
                  countryCode: countryInformation.code,
                });
                setCountryManuallySelected(true);
                setCountryMenuOpen(false);
              } }
              selected={ value.countryCode === countryInformation.code }
            >
              <Stack>
                <StyledCountryName>{ countryInformation.name }</StyledCountryName>
                <StyledCallingCode>+{ countryInformation.callingCode }</StyledCallingCode>
              </Stack>
            </MenuItem>
          )) }
        </StyledMenu>
      )}
    </div>
  );
};

const sortCountriesByName = (
  countryA: CountryInformation,
  countryB: CountryInformation,
) => (
  countryA.name < countryB.name
    ? -1
    : countryA.name === countryB.name
      ? 0
      : 1
);

const parseValue = (value: PhoneNumberFieldValue) => {
  try {
    const asYouType = new AsYouType({ defaultCountry: value.countryCode as CountryCode });
    asYouType.input(value.phoneNumber);
    const countryCode = asYouType.getCountry();
    const phoneNumber = asYouType.getNumber();

    return {countryCode, phoneNumber};
  } catch {
    return {};
  }
}
