import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { defaultPlaceholders, Editor } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Editor/Editor';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  DraftImageContent,
  IMAGE_CAPTION_MAX_LENGTH,
  IMAGE_CAPTION_MIN_LENGTH,
  IMAGE_TEXT_MAX_LENGTH,
  IMAGE_TEXT_MIN_LENGTH,
  ImageCaption,
  ImageCaptionPosition,
  ImageContentValidator,
  ImageEditorContent,
  ImageFit,
  ImageHeight,
} from '../../../Service';
import AlignLeftIcon from '../../../../Assets/img/icons/content-builder/left.svg';
import AlignMiddleIcon from '../../../../Assets/img/icons/content-builder/middle.svg';
import AlignRightIcon from '../../../../Assets/img/icons/content-builder/right.svg';
import AlignTopIcon from '../../../../Assets/img/icons/content-builder/top.svg';
import AlignBottomIcon from '../../../../Assets/img/icons/content-builder/bottom.svg';
import {
  FieldValidationErrors,
  Flex,
  FlexPullRight,
  ImageFitButtons,
  VerticallySpaced
} from '../../../../Common/Component';
import { SizeButton, StyledBadgeContainer, StyledButton, StyledButtonGroup, StyledIconContainer } from './style';
import { CharacterCount } from '../../../../Content/Component';
import { ValidationTree } from '../../../../Common/Model';
import FullSizeIcon from '../../../../Assets/img/icons/content-builder/full.svg';
import MajoritySizeIcon from '../../../../Assets/img/icons/content-builder/majority.svg';
import HalfSizeIcon from '../../../../Assets/img/icons/content-builder/half.svg';
import { Upload } from '../../../../Types';
import { ArrayHelper, RichTextSanitiser } from '../../../../Common/Utility';
import { ColorPicker, Switch } from '../../../../Components';
import {
  useCheckboxChangeEventHandler,
  useEnvironmentSettings,
  useFileUploader,
  useInputChangeEventHandler,
  useUserRoles
} from '../../../../Common/Hook';
import { AiFeatureBadge } from '../../../../Common/Component/AiFeatureBadge/AiFeatureBadge';
import { LinkEditor } from '../LinkEditor/LinkEditor';
import { Form } from '../../../../Forms/Model';
import { FileEntry, MinimalFileEntry } from '../../../../Files/Model/FileEntry';
import { ImageUploadField } from '../../../../Common/Component/ImageUploadField/ImageUploadField';

type HorizontalAlignment = 'left' | 'middle' | 'right';
type VerticalAlignment = 'top' | 'bottom';

export const ImageContentEditor: FC<ContentEditorProps<ImageEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const { addCompletedUpload } = useFileUploader();
  const intl = useIntl();
  const [text, setText] = useState<string>(editorContent.card.content.text || '');
  const [caption, setCaption] = useState<ImageCaption>(editorContent.card.content.caption || {
    textColour: '#ffffff',
    backgroundColour: '#e00669',
    text: '',
    position: 'bottom_middle',
  });
  const splitAlignment = caption.position.split('_');
  const verticalAlignment: VerticalAlignment = splitAlignment?.[0] && isVerticalAlignment(splitAlignment[0])
    ? splitAlignment[0]
    : 'bottom';
  const horizontalAlignment: HorizontalAlignment = splitAlignment?.[1] && isHorizontalAlignment(splitAlignment[1])
    ? splitAlignment[1]
    : 'middle';
  const card = useMemo(() => editorContent.card, [editorContent]);
  const { userIsSuperAdmin } = useUserRoles();
  const {
    filesEnabled = false,
    formsEnabled = false,
  } = useEnvironmentSettings();

  useEffect(() => {
    if (!editorContent.upload) {
      return;
    }

    addCompletedUpload(editorContent.id, editorContent.upload);
  }, [addCompletedUpload, editorContent.id, editorContent.upload]);

  useEffect(() => {
    if (editorContent.card.content.caption === null || caption === editorContent.card.content.caption) {
      return;
    }

    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          caption,
        },
      },
    });
  }, [caption, editorContent, onEditorContentChanged]);

  useEffect(() => {
    if (editorContent.card.content.text === null || text === editorContent.card.content.text) {
      return;
    }

    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text,
        },
      },
    });
  }, [editorContent, onEditorContentChanged, text]);

  const captionTextLabel = intl.formatMessage({
    id: 'broadcasts.content.image.fields.captionText.label',
    description: 'Label for caption text field.',
    defaultMessage: 'Caption text',
  });

  const whenImageUploaded = useCallback((upload: Upload) => {
    onEditorContentChanged({
      ...editorContent,
      upload,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          imageId: upload.id.toString(),
        },
      },
    });

    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            imageId: ImageContentValidator.validateImageId(upload.id.toString()),
            sourceImageId: ImageContentValidator.validateImageId(upload.id.toString()),
          },
        },
      },
    });
  }, [editorContent, onEditorContentChanged, onValidationChanged, validation?.children.content?.children]);

  const whenImageLayoutChanged = useCallback((imageHeight: ImageHeight) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          imageHeight,
          text: imageHeight === 'full' ? null : text,
        },
      },
    });

    if (imageHeight !== 'full') {
      return;
    }

    const contentValidation = validation?.children.content?.children;

    if (!contentValidation) {
      return;
    }

    const { text: textValidation, ...children } = contentValidation;

    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children,
        },
      },
    })
  }, [editorContent, onEditorContentChanged, onValidationChanged, text, validation?.children.content?.children]);

  const whenHorizontalAlignmentChanged = useCallback((horizontalAlignment: HorizontalAlignment) => (
    setCaption(caption => ({
      ...caption,
      position: combineAlignments(verticalAlignment, horizontalAlignment),
    }))
  ), [verticalAlignment]);

  const whenVerticalAlignmentChanged = useCallback((verticalAlignment: VerticalAlignment) => (
    setCaption(caption => ({
      ...caption,
      position: combineAlignments(verticalAlignment, horizontalAlignment),
    }))
  ), [horizontalAlignment]);

  const whenImageFitChanged = useCallback((imageFit: ImageFit) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          imageFit,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenBackgroundColourChanged = useCallback((backgroundColour: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          backgroundColour,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenCaptionBackgroundColourChanged = useCallback((backgroundColour: string) => (
    setCaption(caption => ({
      ...caption,
      backgroundColour,
    }))
  ), []);

  const whenCaptionTextColourChanged = useCallback((textColour: string) => (
    setCaption(caption => ({
      ...caption,
      textColour,
    }))
  ), []);

  const whenCaptionTextChanged = useInputChangeEventHandler((text: string) => (
    setCaption(caption => ({
      ...caption,
      text,
    }))
  ));

  const whenCaptionToggled = useCheckboxChangeEventHandler(useCallback((checked: boolean) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          caption: checked ? caption : null,
        },
      },
    });

    if (checked) {
      return;
    }

    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            caption: {
              errors: [],
              children: {},
            },
          },
        },
      },
    })
  }, [caption, editorContent, onEditorContentChanged, onValidationChanged, validation?.children.content?.children]));

  const whenDownloadableChanged = useCheckboxChangeEventHandler(useCallback((downloadable: boolean) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          downloadable,
        },
      },
    })
  }, [editorContent, onEditorContentChanged]));

  const imageInputLabel = intl.formatMessage({
    id: 'broadcasts.content.image.fields.image.label',
    description: 'Field label for image input.',
    defaultMessage: 'Image',
  });

  const imageBackgroundLabel = intl.formatMessage({
    id: 'broadcasts.content.image.fields.imageBackground.label',
    description: 'Field label for image background input.',
    defaultMessage: 'Image background',
  });

  const whenImageRemoved = useCallback(() => {
    onEditorContentChanged({
      ...editorContent,
      upload: null,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          imageId: '',
        },
      },
    });
  }, [editorContent, onEditorContentChanged]);

  const whenTextBlurred = useCallback(() => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            text: ImageContentValidator.validateText(RichTextSanitiser.stripTags(editorContent.card.content.text || '')),
          },
        },
      },
    })
  ), [editorContent.card.content.text, onValidationChanged, validation?.children.content?.children]);

  const whenBackgroundColourBlurred = () => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            backgroundColour: ImageContentValidator.validateColour(editorContent.card.content.backgroundColour),
          },
        },
      },
    })
  );

  const whenCaptionTextBlurred = () => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            caption: {
              errors: [],
              children: {
                ...validation?.children.content?.children.caption?.children,
                text: ImageContentValidator.validateCaptionText(editorContent.card.content.caption?.text || ''),
              },
            },
          },
        },
      },
    })
  );

  const whenCaptionTextColourBlurred = () => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            caption: {
              errors: [],
              children: {
                ...validation?.children.content?.children.caption?.children,
                textColour: ImageContentValidator.validateColour(editorContent.card.content.caption?.textColour || ''),
              },
            },
          },
        },
      },
    })
  );

  const whenCaptionBackgroundColourBlurred = () => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            caption: {
              errors: [],
              children: {
                ...validation?.children.content?.children.caption?.children,
                backgroundColour: ImageContentValidator.validateColour(editorContent.card.content.caption?.backgroundColour || ''),
              },
            },
          },
        },
      },
    })
  );

  const whenLinkChanged = useCallback((
    draftLink: DraftImageContent,
    form: Form | null,
    file: MinimalFileEntry | null,
  ) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: draftLink,
      },
      form,
      fileEntry: file,
    })
  }, [editorContent, onEditorContentChanged]);

  const whenValidationChanged = useCallback(
    (contentValidation: ValidationTree<DraftImageContent>) => (
      onValidationChanged({
        errors: validation?.errors || [],
        children: {
          ...validation?.children,
          content: contentValidation,
        },
      })
    ),
    [onValidationChanged, validation?.children, validation?.errors],
  );

  const upload = useMemo(() => (
    editorContent.upload
      ? {
        ...editorContent.upload,
        id: String(editorContent.upload.id),
      }
      : null
  ), [editorContent.upload]);

  return (
    <VerticallySpaced gap={ 2 }>
      <h4>
        { imageInputLabel }
      </h4>
      <ImageUploadField
        imageKey={ editorContent.id }
        upload={ upload }
        mode="default"
        onComplete={ whenImageUploaded }
        authoriseEndpoint="broadcasts/authorise-upload"
        outputDimensions={ undefined }
        onRemoveClicked={ whenImageRemoved }
        previewSize="sm"
        imageLibraryEnabled
      />
      <FieldValidationErrors
        fieldName={ imageInputLabel }
        validationErrors={
          validation?.children.content?.children.imageId?.errors.length
            ? validation.children.content.children.imageId.errors
            : validation?.children.content?.children.sourceImageId?.errors || []
        }
      />
      <h4>
        <FormattedMessage
          id="sbroadcasts.content.image.fields.imageFit.label"
          description="Label for image fit buttons when creating a styled image card"
          defaultMessage="Image fit"
        />
      </h4>
      <ImageFitButtons
        imageFit={ editorContent.card.content.imageFit }
        onChange={ whenImageFitChanged }
      />
      { editorContent.card.content.imageFit === 'contain' && (
        <>
          <h4>
            { imageBackgroundLabel }
          </h4>
          <ColorPicker
            value={ editorContent.card.content.backgroundColour }
            onChange={ whenBackgroundColourChanged }
            onBlur={ whenBackgroundColourBlurred }
          />
          <FieldValidationErrors
            fieldName={ imageBackgroundLabel }
            validationErrors={ validation?.children.content?.children.backgroundColour?.errors || [] }
          />
        </>
      ) }
      <label htmlFor="downloadable-toggle">
        <Flex gap={ 2 }>
          <h4>
            <FormattedMessage
              id="broadcasts.content.image.fields.downloadable.label"
              description="Label for downloadable toggle when creating a styled image card"
              defaultMessage="Allow image downloads"
            />
          </h4>
          <FlexPullRight>
            <Switch
              color="primary"
              checked={ editorContent.card.content.downloadable }
              onChange={ whenDownloadableChanged }
              inputProps={ { id: 'downloadable-toggle' } }
            />
          </FlexPullRight>
        </Flex>
      </label>
      <h4>
        <FormattedMessage
          id="broadcasts.content.image.fields.layout.label"
          description="Label layout input in broadcasts image content."
          defaultMessage="Layout"
        />
      </h4>
      <StyledButtonGroup
        color="primary"
        disableElevation
      >
        <SizeButton
          variant={ editorContent.card.content.imageHeight === 'full' ? 'contained' : 'outlined' }
          color={ editorContent.card.content.imageHeight === 'full' ? 'primary' : 'inherit' }
          onClick={ () => whenImageLayoutChanged('full') }
        >
          <StyledIconContainer><FullSizeIcon/></StyledIconContainer>
          <span>
            <FormattedMessage
              id="broadcasts.content.image.fields.imageHeight.full"
              description="Label for full size image when creating a styled image card"
              defaultMessage="Full"
            />
          </span>
        </SizeButton>
        <SizeButton
          variant={ editorContent.card.content.imageHeight === 'majority' ? 'contained' : 'outlined' }
          color={ editorContent.card.content.imageHeight === 'majority' ? 'primary' : 'inherit' }
          onClick={ () => whenImageLayoutChanged('majority') }
        >
          <StyledIconContainer><MajoritySizeIcon/></StyledIconContainer>
          <span>
            <FormattedMessage
              id="broadcasts.content.image.fields.imageHeight.70/30"
              description="Label for 70/30 image:text ratio when creating a styled image card"
              defaultMessage="70/30"
            />
          </span>
          <StyledBadgeContainer>
            <AiFeatureBadge hideText/>
          </StyledBadgeContainer>
        </SizeButton>
        <SizeButton
          variant={ editorContent.card.content.imageHeight === 'half' ? 'contained' : 'outlined' }
          color={ editorContent.card.content.imageHeight === 'half' ? 'primary' : 'inherit' }
          onClick={ () => whenImageLayoutChanged('half') }
        >
          <StyledIconContainer><HalfSizeIcon/></StyledIconContainer>
          <span>
            <FormattedMessage
              id="broadcasts.content.image.fields.imageHeight.50/50"
              description="Label for 50/50 image:text ratio when creating a styled image card"
              defaultMessage="50/50"
            />
          </span>
          <StyledBadgeContainer>
            <AiFeatureBadge hideText/>
          </StyledBadgeContainer>
        </SizeButton>
      </StyledButtonGroup>
      { editorContent.card.content.imageHeight !== 'full' && (
        <>
          <Flex>
            <h4>
              <FormattedMessage
                id="broadcasts.content.image.fields.text.label"
                description="Label for subtext input in broadcasts image content."
                defaultMessage="Text"
              />
            </h4>
            <FlexPullRight>
              <CharacterCount
                minimum={ IMAGE_TEXT_MIN_LENGTH }
                maximum={ IMAGE_TEXT_MAX_LENGTH }
                current={ RichTextSanitiser.stripTags(text).length }
              />
            </FlexPullRight>
          </Flex>
          <Editor
            availablePlaceholders={ defaultPlaceholders }
            value={ text }
            onChange={ setText }
            onBlur={ whenTextBlurred }
            analyticsContext="Broadcast image content"
            aiPromptOptions={ {
              maxLength: IMAGE_TEXT_MAX_LENGTH,
            } }
          />
          <FieldValidationErrors
            fieldName={ intl.formatMessage({
              id: 'broadcasts.content.image.fields.text.name',
              defaultMessage: 'text',
            }) }
            validationErrors={ validation?.children.content?.children.text?.errors || [] }
          />
        </>
      ) }
      <label htmlFor="caption-toggle">
        <Flex gap={ 2 }>
          <h4>
            <FormattedMessage
              id="broadcasts.content.image.fields.captionToggle.name"
              description="Label for caption toggle input"
              defaultMessage="Caption"
            />
          </h4>
          <FlexPullRight>
            <Switch
              color="primary"
              checked={ !!editorContent.card.content.caption }
              onChange={ whenCaptionToggled }
              inputProps={ { id: 'caption-toggle' } }
            />
          </FlexPullRight>
        </Flex>
      </label>
      { editorContent.card.content.caption && (
        <>
          <div>
            <TextField
              margin="dense"
              label={ captionTextLabel }
              fullWidth
              value={ caption.text }
              onChange={ whenCaptionTextChanged }
              onBlur={ whenCaptionTextBlurred }
            />
            <CharacterCount
              current={ caption.text.length || 0 }
              minimum={ IMAGE_CAPTION_MIN_LENGTH }
              maximum={ IMAGE_CAPTION_MAX_LENGTH }
            />
            <FieldValidationErrors
              fieldName={ captionTextLabel }
              validationErrors={ (validation?.children.content?.children.caption?.children as ValidationTree<ImageCaption>['children'])?.text?.errors || [] }
            />
          </div>
          <h4>
            <FormattedMessage
              id="broadcasts.content.image.fields.textAlign.label"
              description="Label for text alignment buttons when creating a styled image card"
              defaultMessage="Caption alignment"
            />
          </h4>
          <StyledButtonGroup
            disableElevation
            color="primary"
          >
            <StyledButton
              variant={ horizontalAlignment === 'left' ? 'primary' : 'secondary' }
              onClick={ () => whenHorizontalAlignmentChanged('left') }
            >
              <AlignLeftIcon/>
            </StyledButton>
            <StyledButton
              variant={ horizontalAlignment === 'middle' ? 'primary' : 'secondary' }
              onClick={ () => whenHorizontalAlignmentChanged('middle') }
            >
              <AlignMiddleIcon/>
            </StyledButton>
            <StyledButton
              variant={ horizontalAlignment === 'right' ? 'primary' : 'secondary' }
              onClick={ () => whenHorizontalAlignmentChanged('right') }
            >
              <AlignRightIcon/>
            </StyledButton>
          </StyledButtonGroup>
          <StyledButtonGroup
            disableElevation
            color="primary"
          >
            <StyledButton
              variant={ verticalAlignment === 'top' ? 'primary' : 'secondary' }
              onClick={ () => whenVerticalAlignmentChanged('top') }
            >
              <AlignTopIcon/>
            </StyledButton>
            <StyledButton
              variant={ verticalAlignment === 'bottom' ? 'primary' : 'secondary' }
              onClick={ () => whenVerticalAlignmentChanged('bottom') }
            >
              <AlignBottomIcon/>
            </StyledButton>
          </StyledButtonGroup>
          <h4>
            <FormattedMessage
              id="broadcasts.content.image.fields.textColour.label"
              description="Label for text foreground colour input when creating a styled image card"
              defaultMessage="Caption text colour"
            />
          </h4>
          <ColorPicker
            value={ caption.textColour }
            onChange={ whenCaptionTextColourChanged }
            onBlur={ whenCaptionTextColourBlurred }
          />
          <h4>
            <FormattedMessage
              id="broadcasts.content.image.fields.textBackgroundColour.label"
              description="Label for text background colour input when creating a styled image card"
              defaultMessage="Caption background colour"
            />
          </h4>
          <ColorPicker
            value={ caption.backgroundColour }
            onChange={ whenCaptionBackgroundColourChanged }
            onBlur={ whenCaptionBackgroundColourBlurred }
          />
        </>
      ) }
      <LinkEditor
        allowFile={ filesEnabled && userIsSuperAdmin }
        allowForm={ formsEnabled }
        draftLink={ card.content }
        validation={ validation?.children.content || { errors: [], children: {} } }
        form={ editorContent.form }
        file={ editorContent.fileEntry }
        onChange={ whenLinkChanged }
        onValidationChange={ whenValidationChanged }
      />
    </VerticallySpaced>
  );
};

const isHorizontalAlignment = ArrayHelper.createTypeGuard<HorizontalAlignment>(['left', 'middle', 'right']);
const isVerticalAlignment = ArrayHelper.createTypeGuard<VerticalAlignment>(['top', 'bottom']);
const combineAlignments = (verticalAlignment: VerticalAlignment, horizontalAlignment: HorizontalAlignment) => (
  [verticalAlignment, horizontalAlignment].join('_') as ImageCaptionPosition
);
