import { ComponentProps, FC, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { StyledLabel } from './style';
import { PresentationIcon } from '../../Content/PresentationIcon/PresentationIcon';
import {
  FieldValidationErrors
} from '../../../../../src/react/Common/Component/FieldValidationErrors/FieldValidationErrors';
import { UniqueIdGenerator } from '../../../../../src/react/Common/Utility/UniqueIdGenerator';
import { SvgComponent } from '../../../../../src/react/Common/Model/SvgComponent';
import { ErrorHandler } from '../../../../../src/react/Common/Hook/useDefaultValidationErrorHandlers';
import { ValidationError } from '../../../../../src/react/Common/Model/ValidationError';
import { NoMarginTextField } from '../../../../../src/react/Common/Component/NoMarginTextField/NoMarginTextField';
import { Typography } from '../../Content/Typography/Typography';

type Props = {
  id?: string;
  required?: boolean;
  label: string;
  errors?: ValidationError[];
  fullWidth?: boolean;
  autofocus?: TextFieldProps['autoFocus'];
  startAdornment?: {
    type: 'icon';
    IconComponent: SvgComponent;
  } | {
    type: 'node';
    node: ReactNode;
  }
  type?: TextFieldProps['type'];
  autoComplete?: TextFieldProps['autoComplete'];
  pattern?: string | RegExp;
  customHandlers?: ErrorHandler[];
  className?: string;
  renderField?: (props: ComponentProps<typeof NoMarginTextField>) => JSX.Element;
};

export const LabelledField: FC<Props & Omit<ComponentProps<typeof NoMarginTextField>, 'useNewTheme' | 'label'>> = ({
  id,
  label,
  required = false,
  errors = [],
  fullWidth = false,
  value,
  onChange,
  autofocus = false,
  startAdornment,
  type,
  autoComplete,
  pattern,
  customHandlers,
  className,
  renderField,
  InputProps,
  ...props
}) => {
  const fallbackId = UniqueIdGenerator.generate();

  const fieldProps = useMemo<ComponentProps<typeof NoMarginTextField>>(() => ({
    id: id || fallbackId,
    ...(pattern ? { inputProps: { pattern } } : {}),
    onInvalid: event => event.preventDefault(),
    variant: 'outlined',
    error: !!errors?.length,
    fullWidth,
    value,
    onChange,
    autoFocus: autofocus,
    type,
    autoComplete,
    ...(startAdornment ? {
        InputProps: {
          ...InputProps,
          startAdornment: (
            <InputAdornment position="start">
              {
                startAdornment.type === 'icon'
                  ? (
                    <PresentationIcon
                      IconComponent={ startAdornment.IconComponent }
                      color="grey.500.main"
                    />
                  )
                  : startAdornment.node
              }
            </InputAdornment>
          ),
        }
      } : { InputProps }),
    useNewTheme: true,
    ...props,
    margin: 'dense',
    size: 'small',
  }), [
    id,
    fallbackId,
    pattern,
    errors?.length,
    fullWidth,
    value,
    onChange,
    autofocus,
    type,
    autoComplete,
    startAdornment,
    InputProps,
    props
  ]);

  return (
    <StyledLabel
      fullWidth={ fullWidth }
      htmlFor={ id || fallbackId }
      className={ className }
    >
      <Stack
        direction="column"
        gap={ 1 }
      >
        <Typography
          size="sm"
          weight="medium"
          color="grey.900.main"
        >
          {
            required
              ? (
                <FormattedMessage
                  description="Field name with required indicator"
                  defaultMessage="{ fieldName }*"
                  values={ {
                    fieldName: label,
                  } }
                />
              )
              : label
          }
        </Typography>
        {
          renderField
            ? renderField(fieldProps)
            : (
              <NoMarginTextField
                { ...fieldProps }
              />
            )
        }
        { !!errors?.length && (
          <FieldValidationErrors
            fieldName={ label }
            validationErrors={ errors }
            customHandlers={ customHandlers }
          />
        ) }
      </Stack>
    </StyledLabel>
  );
};
