import { FormattedMessage } from 'react-intl';
import { FC } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import BetaIcon from '../../../../Asset/Icon/figma/beaker-02.svg';
import { StyledBetaButton } from './style';

type Props = {
  onClick: () => void;
  className?: string;
};

export const AiBetaButton: FC<Props> = ({
  onClick,
  className,
}) => (
  <StyledBetaButton
    onClick={ onClick }
    className={ className }
  >
    <Stack
      noWrap
      gap={ 1 }
    >
      <PresentationIcon
        IconComponent={ BetaIcon }
        size={ 4 }
      />
      <FormattedMessage
        description="Beta information link used in AI menu"
        defaultMessage="Beta mode"
      />
    </Stack>
  </StyledBetaButton>
);
